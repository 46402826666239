import React, { useState } from "react";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../../utils/GlobalStyle";
import { anual_codes, montly_codes } from "../../utils/plans.codes";
import Footer from "./Footer";
// import the navbar
import AppBar from "./AppBar";
import { Link } from "react-router-dom";

import {
    Container,
    Grid,
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Button
} from "@mui/material";


// Styled components
const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Header = styled.div`
    padding: ${(props) => props.theme.spacing(6)} 0;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Wrapper = styled.div`
    padding: 40px;
    text-align: center;
`;

const selectPlan = (plan) => {
    localStorage.setItem("plan", plan)
}

const getButtons = (type) => {
    var color = "primary"
    var plans = anual_codes
    if(type === "monthly") {
        color = "info"
        plans = montly_codes
    
    }
    return plans.map((plan) =>  <TableCell key={plan+color} align="center"><Button key={plan} fullWidth variant="contained" color={color} onClick={()=> selectPlan(plan)} component={Link} to="/auth/sign-up" >Elegir plan</Button></TableCell> ) ;
}


const PricingDetails = () => {

    const [mood, setMood] = useState('annual');
    const [backgound, setBackgound] = useState('#fec601')

    const handleMood = (e, newMood) => {
        if (newMood !== null) {
            setMood(newMood);
            setBackgound(newMood === 'annual' ? '#fec601' : '#5271ff')
        }
    }

    const createData = (name, basic, standard, advanced) => {
        return { name, basic, standard, advanced };
    }
    const rows = {
        "annual": [
            createData('Precio', "Q4800/año", "Q15000/año", "Q30000/año"),
            createData('Número de suscripciones para recibir notificaciones', "Ilimitado", "Ilimitado", "Ilimitado"),
            createData('Suscripción y rastreo de palabras clave', "10 palabras", "10 palabras", "50 palabras"),
            createData('Usuarios para recibir notificaciones', "3 usuarios", "3 usuarios", "10 usuarios"),
            createData('Búsqueda de precios y marcas', "", "Ilimitado", "Ilimitado"),
            createData('Notificaciones de eventos en tiempo real', "Ilimitado", "Ilimitado", "Ilimitado"),
            createData('Soporte técnico', "Ilimitado", "Ilimitado", "Ilimitado"),
        ],
        "monthly": [
            createData('Precio', "Q550/mes", "Q1400/mes", "Q3200/mes"),
            createData('Número de suscripciones para recibir notificaciones', "Ilimitado", "Ilimitado", "Ilimitado"),
            createData('Suscripción y rastreo de palabras clave', "10 palabras", "10 palabras", "50 palabras"),
            createData('Usuarios para recibir notificaciones', "3 usuarios", "3 usuarios", "10 usuarios"),
            createData('Búsqueda de precios y marcas', "", "Ilimitado", "Ilimitado"),
            createData('Notificaciones de eventos en tiempo real', "Ilimitado", "Ilimitado", "Ilimitado"),
            createData('Soporte técnico', "Ilimitado", "Ilimitado", "Ilimitado"),
        ]
    }
    return (
        <Root>
            { /* 
                these components provide the theme with material design styles
                is similar to calling the file directly
                <link src = "assets/css/globalstyle.css">
            */ }
            <CssBaseline />
            <GlobalStyle />
            <AppContent >
                { /* place the navbar */}
                <AppBar timeOut={1500} />
                <Wrapper py={20} >
                    <Container >
                        <Header>
                            <Typography variant="h3" gutterBottom align="center">
                                Conoce nuestros planes
                            </Typography>
                            <div style={{ marginTop: "40px" }}>
                                <ToggleButtonGroup
                                    value={mood}
                                    exclusive
                                    onChange={handleMood}
                                    color={mood === 'annual' ? "primary" : "info"}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value="annual" aria-label="left aligned">
                                        Anual
                                    </ToggleButton>
                                    <ToggleButton value="monthly" aria-label="centered">
                                        Mensual
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Header>
                        <Grid container justifyContent="center" >
                            <TableContainer component={Paper} >
                                <Table aria-label="simple table">
                                    <TableHead style={{ background: backgound }}>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="center">Proveedor Estándar</TableCell>
                                            <TableCell align="center">Proveedor Avanzado</TableCell>
                                            <TableCell align="center">Proveedor Experto</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows[mood].map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="center">{row.basic}</TableCell>
                                                <TableCell align="center">{row.standard}</TableCell>
                                                <TableCell align="center">{row.advanced}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell align="center"></TableCell>
                                            {
                                                getButtons(mood)
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                </Wrapper>
 
                <Footer />
            </AppContent>
            
        </Root>
    )
}

export default PricingDetails;