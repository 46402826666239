import axios from '../services/axios.default'
import useAuth from './useAuth.js'

const useRefreshToken = () => {
    const { setAccessToken, signOut} = useAuth();

    const refresh = async () => {
        const refreshToken = localStorage.getItem("refreshToken")
        
        const response = await axios.post('/business/extend_session/', {'refresh': refreshToken})
        .then(response => response)
        .catch(error => console.log(error))
        
        if(!response){
            await signOut();
            return undefined;
        } else {
            setAccessToken(response.access);
            return response?.data?.access;
        }
        
    }

    return refresh;
}

export default useRefreshToken;