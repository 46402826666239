import videos from '../home/videos';
import { NavLink } from "react-router-dom";
import { Abc, QuestionAnswer, FlagCircle } from "@mui/icons-material";
import { Grid, Typography, CardActionArea, CardMedia, Container, Box, Card, CardContent  } from "@mui/material";

const HelpPage = () => {
  return (
   <Container maxWidth="lg">
    <Grid container spacing={12}>
      <Grid item md={4}>
        <Card>
            <CardActionArea to="/dashboard/docs/video/0" component={NavLink} >
              <Typography gutterBottom variant="h5" component="div" align="center">
                  <Box sx={{p:8}}>
                    <FlagCircle sx={{ width: 86, height: 86}} />
                  </Box>
                </Typography>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {videos[0].title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {videos[0].description}                
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      <Grid item md={4}>
      <Card>
          <CardActionArea  to="/dashboard/docs/dictionary" component={NavLink} >
            <Typography gutterBottom variant="h5" component="div" align="center" >
                <Box sx={{p:8}}>
                  <Abc sx={{ width: 86, height: 86}} />
                </Box>
              </Typography>
          
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Diccionario
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sección con términos importantes y todo el catalogo de palabras que encuentras utilizando tanto nuestra app como GuateCompras.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={4}>
      <Card>
          <CardActionArea to="/dashboard/docs/questions" component={NavLink} >
            <Typography gutterBottom variant="h5" component="div" align="center">
                <Box sx={{p:8}}>
                  <QuestionAnswer sx={{ width: 86, height: 86}} />
                </Box>
              </Typography>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Preguntas Frecuentes
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sección con las preguntas mas frecuentes que se pueden presentar al utilizar por primera ver al utilizar GuateCompras o nuestra app.
                </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {videos.slice(1).map((video, index) => (
      <Grid item md={6}>
        <Card >
            <CardActionArea  sx={{ height: '280px'}} to={`/dashboard/docs/video/${index+1}`} component={NavLink} >
              <CardMedia
                component="img"
                height={'140px'}
                image={video.mini}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {video.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {video.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
      </Grid>
      ))}
    </Grid>
   </Container>
  );
};
export default HelpPage;
