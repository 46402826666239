import React from 'react'
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

/* MUI Componentes */
import {
    Divider as MuiDivider,
} 
from "@mui/material";
/* Styled Componentes */
const MUIDivider = styled(MuiDivider)(spacing);

/* 
    A divider is a thin line that groups content in lists and layouts.
    Dividers separate content into clear groups.

    You can see the properties that the component supports here: https://mui.com/components/dividers/#main-content
    
*/
const Divider = (props) => {
    return (<MUIDivider {...props} />)
}


export default Divider


