const questions = [  
    {
      question: '¿Es legal el proceso de obtener los datos? ',
      answer: 'Si, los datos son obtenidos legalmente de fuentes públicas a través de un proceso respaldado por el artículo 30 y 31 de la Constitución de la República de Guatemala, Ley de Acceso a la Información Pública y Ley de Contrataciones del Estado.'
    },
    {
      question: '¿Cada cuanto se actualiza la base de datos?',
      answer: 'La base de datos se actualiza diariamente.'
    },
    {
      question: '¿Por qué no estoy recibiendo notificaciones?',
      answer: 'Las notificaciones únicamente funcionan en planes pagados. Para configurar las notificaciones sigue este tutorial. '
    },
    {
      question: '¿Por qué no puedo activar mis suscripciones?',
      answer: 'Las suscripciones únicamente se pueden activar en planes pagados. Para activar suscripciones sigue este tutorial. '
    },
    {
      question: '¿Por qué no puedo activar mis seguidores?',
      answer: 'Los seguidores únicamente se pueden activar en planes pagados. Para activar seguidores sigue este tutorial.'
    }
  ];
  
  export default questions;