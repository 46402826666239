import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Grid, Typography, Button as MuiButton, CardMedia, Container } from "@mui/material";

import { spacing } from "@mui/system";

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const images = [
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603215/front%20doc/lakeckzbopcfbdoj0zju.png",
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603213/front%20doc/smbpoydvqfgj7hmb2dmd.png",
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603213/front%20doc/vqmx6ujyrnekai32kwwi.png",
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603216/front%20doc/settedsdgtnqkw0rlha5.png",
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603214/front%20doc/zzm5xy0oga2pi9jawled.png",
    "https://res.cloudinary.com/dos6zlnm9/image/upload/v1705603217/front%20doc/lqb7r7mhhhhbjf0bxfo6.png",
]
const Institution = () => {
    
    return (
        <Wrapper py={10} id={"chartcontainer"}>
            <Container>
                <Grid container justifyContent="center">
                    {
                        images.map((item, idx) => (
                            <Grid key={idx} item p={8} md sx={12} > 
                                <img width={300} src={item} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Wrapper>
    )
}

export default Institution;
