import React from "react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import SidebarNavList from "./SidebarNavList";

import styled, { css } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import "../../vendor/perfect-scrollbar.css";

//MUI Components
import { Drawer as MuiDrawer, ListItemButton, List } from "@mui/material";
import { navItems, footerItems, adminItems, navItemsfree, navItemsPS } from "./dashboradItems";
import SidebarHeader from "./SidebarHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo from "../../vendor/faviconW.svg";
import useAuth from "../../hooks/useAuth";

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  color: #fff;
  width: 258px;
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled.div`
  margin: 5px 10px 0 -2px;
  background-repeat: no-repeat;
  width: 40px;
  height: 43px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
`;

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Sidebar = ({ ...rest }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const { user } = useAuth();

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/dashboard/home" className="fontFamily">
        <BrandIcon style={{ backgroundImage: `url(${logo})` }}></BrandIcon>
        GUATE DATA SUITE
      </Brand>
      <SidebarHeader data={user} />
      <ScrollbarComponent>
      {adminItems && user?.is_superuser && (
          <List disablePadding>
            <Items>
              {adminItems.map((item) => (
                <SidebarNavList
                  key={item.title}
                  pages={item.pages}
                  title={item.title}
                />
              ))}
            </Items>
          </List>
        )}
        {

        }
        {(user?.plan?.code == "BA" || user?.plan?.code == "BAA")? (
           <List disablePadding>
           <Items>
             {navItemsfree &&
               navItemsfree.map((item) => (
                 <SidebarNavList
                   key={item.title}
                   pages={item.pages}
                   title={item.title}
                 />
               ))}
           </Items>
         </List>
        ):(
          <>
          {(user?.plan?.code == "PS" || user?.plan?.code == "PSA")? (
            <List disablePadding>
              <Items>
                {navItemsPS &&
                  navItemsPS.map((item) => (
                    <SidebarNavList
                      key={item.title}
                      pages={item.pages}
                      title={item.title}
                    />
                  ))}
              </Items>
            </List>
          ): (
            <List disablePadding>
            <Items>
              {navItems &&
                navItems.map((item) => (
                  <SidebarNavList
                    key={item.title}
                    pages={item.pages}
                    title={item.title}
                  />
                ))}
            </Items>
          </List>
          )}
          </>
         
        )}
       
        <List disablePadding>
          <Items>
            {footerItems &&
              footerItems.map((item) => (
                <SidebarNavList
                  key={item.title}
                  pages={item.pages}
                  title={item.title}
                  target={item.target}
                />
              ))}
          </Items>
        </List>
      </ScrollbarComponent>
    </Drawer>
  );
};

export default Sidebar;
