import React from "react";
import styled from "styled-components/macro";

import { 
  Avatar, 
  Badge, 
  Grid, 
  Typography,
  Chip,
} from "@mui/material";

import { plans_all } from "../../utils/plans.codes";

const Header = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const HeaderText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;
const HeaderSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const HeaderBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarHeader = ({ ...rest }) => {
  const plan = plans_all.find(element => element.plan === rest.data.plan.code);
  return (
    <Header {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <HeaderBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {!!rest.data && <Avatar alt={rest.data.first_name} src={rest.data.photo_url} sx={{ width: 44, height: 44 }}/>}
            {/* Demo data */}
            {!rest.data && (
              <Avatar
                alt="NO DATA"
                src=""
              />
            )}
          </HeaderBadge>
        </Grid>
        <Grid item>
          {rest.data && <HeaderText variant="body2">{rest.data.first_name} {rest.data.last_name}</HeaderText>}
          {plan && <HeaderSubText variant="caption">{plan &&(<Chip size='small' label={<Typography variant="caption" >{plan.name}</Typography>} color={plan.variant} />)}</HeaderSubText>}
        </Grid>
      </Grid>
    </Header>
  );
};

export default SidebarHeader;
