import { createContext, useEffect, useReducer } from "react";
import { setSession } from "../utils/jwt";
import { encrypt } from "../utils/encrypt";
import {
  signUpService,
  signInService,
  resetPasswordService,
} from "../services/auth.service";
import { useNavigate } from "react-router-dom";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const ACCESS_TOKEN = "ACCESS_TOKEN";
const ENCRYPT = "encrypt-procesador-guate";
const VALID_STATUS = 200;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
  refreshToken: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "SIGN_UP":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case "ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");
        const user = JSON.parse(localStorage.getItem("user"));

        if ( !accessToken || !refreshToken ) return;

        setSession(refreshToken, accessToken, user);

        if ( user || accessToken || refreshToken ) {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              accessToken,
              refreshToken,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              accessToken: null,
              refreshToken: null,
            },
          });
          navigate("/auth/sign-in");
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            accessToken: null,
            refreshToken: null,
          },
        });
        navigate("/auth/sign-in");
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email, password) => {
    const response = await signInService(email, encrypt(ENCRYPT, password));
    if (response.status !== VALID_STATUS) return response;

    const { token, user } = response.data;
    setSession(token.refresh, token.access, user);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        accessToken: token.access,
        refreshToken: token.refresh,
      },
    });

    return response;
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (body) => {
    body.password = encrypt(ENCRYPT, body.password);
    const res = await signUpService(body);
    if (res.status === 200) {
      dispatch({
        type: SIGN_UP,
        payload: {
          body,
        },
      });
      return res.data;
    } else {
    }
  };

  const resetPassword = async (email) => {
    const response = await resetPasswordService(
      email,
      encrypt(ENCRYPT, email),
      encrypt(ENCRYPT, encrypt(ENCRYPT, email))
    );
    return response;
  };

  const setAccessToken = async (accessToken) => {
    dispatch({
      type: ACCESS_TOKEN,
      payload: {
        accessToken,
      },
    });
  };
  
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        setAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
