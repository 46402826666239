import React, { useState, useRef, useEffect } from "react"
import { Editor } from '@tinymce/tinymce-react';

import useAuth from "../../hooks/useAuth";
import useNotify from "../../hooks/useNotify";

import {
    Button,
    TextField,
} from "@mui/material";

import {
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import {
  create_post,
} from '../../services/blog.service';


const CreateBlog = () => {
    const { user } = useAuth();
    const { notifyShow } = useNotify();

    const editorRef = useRef(null);
    const [title, setTitle] = useState();

    const log = async() => {
      if (editorRef.current) {
        const postcreate = await create_post(user.id, title, {content: editorRef.current.getContent()})

        if (postcreate) {
          return notifyShow(postcreate.message, "success")
        }
      }
    };

    return (
      <>
        <Breadcrumb
          pagetitle="Posts"
          title="Posts"
          steps={[{ title: "Posts", to: "" }]}
          button={
            <Button
              variant="contained"
              onClick={log}
              color="primary"
              size="large"
              startIcon={<AddCircleIcon />}
            >
              Agregar post
            </Button>
          }
        />

        <TextField 
          name="title"
          label="Titulo (separar palabras con '-' sin espacios)"
          helperText="url con la que se anexara el post. ejem /blog/post-titular"
          fullWidth
          sx={{ mb: 12 }}
          onChange={e => setTitle(e.target.value)}
        />
        <Editor
          apiKey='ve5o659gx21ag7qdt5al3wmuvg0yuxwff0syy01i8rrrmw8q'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue="<p>This is the initial content of the editor.</p>"
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | image | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </>
    );
}

export default CreateBlog;