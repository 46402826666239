import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";


import {
    Grid,
    Typography as MuiTypography,
    Container,
    Button as MuiButton,
} from "@mui/material";

// Styled components
const Typography = styled(MuiTypography)(spacing);

const Title = styled(Typography)`
  line-height: 1.4;
  font-size: 1.75rem;
  text-align: center;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: 2.5rem;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2.5rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 3.9rem;
  }
  span {
    color: #000;
  }
`;

const Button = styled(MuiButton)`
  width: 300px;
  font-size: 1.2rem;
  font-weight: 4rem;
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
`;

const InitPage = () => {
  return (
    <Container  align="center">
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height={600}
        >
        <Grid item >
        <Title variant="h1" gutterBottom>
            404 ERROR
        </Title>
        <Title variant="h1" gutterBottom>
            PAGINA NO ENCONTRADA
        </Title>
            <Button
                ml={2}
                color="primary"
                variant="contained"
                component={Link} 
                to="/" 
              >
                Regresar
              </Button>
        </Grid>

        </Grid>

            </Container>

  );
};

export default InitPage;