import React, { Fragment, useState, useEffect } from "react";

import CardContent from "@mui/material/CardContent";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import HelpSidebar from "./Help.Sidebar";

import { Search as SearchIcon } from "@mui/icons-material";

import { Grid, Typography, Paper, InputBase, IconButton, Card, Container } from "@mui/material";

import questions from "./questionsText";



const HelpQuestions = () => {
  // SEARCH
  const [q, setQ] = useState("");
  const [dataFilter, setDatafilter] = useState([]);

  const search = (row) => {
    return row.filter(
      (row) =>
        row["question"].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  useEffect(() => {
    const reloand = async () => {
      setDatafilter(search(questions));
    };
    reloand();
  }, [questions, q]);

  return (

    <Container maxWidth="lg">
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item xs={12} md={12}>
        <Breadcrumb
          pagetitle="Diccionario"
          title="Diccionario"
          steps={[
            { title: "Preguntas", to: "dashboard/docs/questions" },
            { title: "Diccionario", to: "" },
          ]}
        />
      </Grid>
    </Grid>

    <Grid container justifyContent="center">
      <Grid item md={8}>
      <Grid container spacing={2} py={5}>
        <Grid item xs={12} md={12}>
          <Paper
            variant="outlined"
            elevation={3}
            sx={{
              p: "0px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Buscar NOG"
              onChange={(e) => setQ(e.target.value)}
              type="text"
            />
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              {dataFilter.map((data, index) => (
                  <div key={index} style={{ padding: "15px" }}>
                    <Typography variant="h5" >
                      {data.question}
                    </Typography>
                    <Typography variant="body2">{data.answer}</Typography>
                  </div>
                ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      </Grid>
      <Grid item md={4}>
        <HelpSidebar />
      </Grid>

    </Grid>
  </Container>
  );
};
export default HelpQuestions;
