import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import '../../vendor/font/styles.css'

// utils
import GlobalStyle from "../../utils/GlobalStyle";
import logo from "../../vendor/favicon.svg";
import { plans_all } from "../../utils/plans.codes";

// MUI Components
import {
  CssBaseline,
  Hidden,
  Paper as MuiPaper,
  Toolbar,
  LinearProgress,
  Grid,
  Container,
  Typography,
} from "@mui/material";

// Template components
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Payment from "../payments/Payment";

// custom hooks
import { useTheme } from "@mui/material/styles";

/*";

import Footer from "../components/Footer";
import Settings from "../components/Settings";*/

/* Styled Components */

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 258px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const MainContent = styled(styled(MuiPaper)(spacing))`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Image = styled.div`
  min-height: 200px;
  min-width: 200px;
`;


/*
This component is responsible for rendering the navbar, sidebar and the rest of the pages
You can obtain information on the components used here https://mui.com

*/

const DashboardLayout = ({ children }) => {
  //This property is used to identify whether or not the mobile view is shown.
  const [mobileOpen, setMobileOpen] = useState(false);
  const [progress, setProgress] = useState(true);
  const [openPayment, setOpenPayment] = useState(false);
  const [text, setText] = useState(0);
  const navigate = useNavigate();
  const sampleLocation = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const recurring = JSON.parse(localStorage.getItem('recurring_data'));

  const [plan_act, setPlanAct] = useState();

  //this function changes the state of the mobile flag
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    if (!user) navigate("/auth/sign-in");

    if (
      sampleLocation.pathname === "/dashboard" ||
      sampleLocation.pathname === "/dashboard/"
    )
      navigate("/dashboard/home");
    if (recurring && recurring?.name_user === user?.first_name) {
      const plan_actFind = plans_all.filter((item) => item.plan === recurring.selected_plan);
      setPlanAct(plan_actFind);
      setOpenPayment(true);
    }
    setTimeout(() => {
      setProgress(false);
    }, 1700)
  }, []);
  //get current theme
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      {!progress? (
        <Root>
          {/* 
      these components provide the theme with material design styles
      is similar to calling the file directly
      <link src = "assets/css/globalstyle.css">
      */}
          <CssBaseline />
          <GlobalStyle />

          <Drawer>
            {/* Hidden is a component that allows you to hide everything that is inside
            it is used to hide the mobile view when n need to show
        */}
            <Hidden lgUp implementation="js">
              {/*Insert the sidebar */}
              <Sidebar
                PaperProps={{ style: { width: 258 } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden mdDown implementation="css">
              {/*Insert the sidebar */}
              <Sidebar PaperProps={{ style: { width: 258 } }} />
            </Hidden>
          </Drawer>
          <AppContent>
            {(recurring && recurring?.name_user === user?.first_name) && (<Payment plan={plan_act[0]} setOpen={setOpenPayment} open={openPayment} /> )}
            {/*Insert the navbar */}
            <Navbar onDrawerToggle={handleDrawerToggle} notificationList={[]} />

            <MainContent p={isLgUp ? 12 : 5}>
              <Toolbar>{children}</Toolbar>

              <Outlet />
            </MainContent>
          </AppContent>
        </Root>
      ) : (
        <Container align="center">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                height={600}
                spacing={6}
              >
                <Grid item >
                  <Image style={{backgroundImage: `url(${logo})` }}></Image>
                
                </Grid>
                
                <Grid item className="fontFamily">
                <Typography variant="h3" className="fontFamily" style={{color: '#5271ff'}}>
                    GUATE DATA SUITE
                  </Typography>
                  <LinearProgress/>
                </Grid>
              </Grid>
            </Container>
      )}
    </>
  );
};

export default DashboardLayout;
