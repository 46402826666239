import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components/macro";

import { useStripe, useElements } from "@stripe/react-stripe-js";

import useAuth from "../../hooks/useAuth";
import useNotify from "../../hooks/useNotify";

import {
  Grid,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  DialogActions,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  create_stripe_subscription,
  get_plans
} from "../../services/recurrente.service";

import {
  Close as CloseIcon,
  Receipt as ReceiptIcon,
  Payment as PaymentIcon,
  CreditCard as CreditCardIcon,
} from "@mui/icons-material";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const CardPaymentStyled = styled.div`
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 12px 16px;
  margin: 10px 0;
`;

const ButtonPay = styled.button`
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  &:hover {
    filter: contrast(115%);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ImgLogo = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 2px;
`;

const cardStyle = {
  style: {
    base: {
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const stripePromise = loadStripe(
  "pk_test_51MQwTmAXJNCnHW24GdFRhwFOvc2z5KmITxvDtB4VcD4PGU6AVFsv1fYtnpwv1siGe5mGW0PaelQnZMFKdbcKbexW0033ySzv5q"
);

const payment_method_buttons = [
  { value: "TRANSFERENCIA", name: "Transferencia", logo: <ReceiptIcon /> },
  { value: "STRIPE", name: "Tarjeta Credito/Debito", logo:  <CreditCardIcon/> },
];

const CheckoutForm = ({ setOpen, stripe_user, plan_code }) => {
  const [processing, setProcessing] = useState("");
  const { notifyShow } = useNotify();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const plans_api = await get_plans();
    const plan_actFind = plans_api.filter((item) => item.code === plan_code);

    const subscription_payment = await create_stripe_subscription(
      stripe_user.stripe_user_id, // user_id user.stripe_id
      plan_actFind[0].stripe_price ?? "price_1MWXaGAXJNCnHW243lzZKXgf" //subscription_id
    );

    const payload = await stripe.confirmCardPayment(
      subscription_payment.clientSecret,
      {
        payment_method: {
          type: "card",
          card: elements.getElement(CardNumberElement),
          card: elements.getElement(CardExpiryElement),
          card: elements.getElement(CardCvcElement),
        },
      }
    );

    if (payload.error) {
      notifyShow(`Payment failed ${payload.error.message}`, "error", "filled", [
        "top",
        "center",
      ]);
      setProcessing(false);
    } else {
      setProcessing(false);
      notifyShow(
        "Pago Completado, inicie sesión nuevamente para aplicar los cambios",
        "success",
        "filled",
        ["top", "center"]
      );
      setOpen(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {processing && <LinearProgress />}
      <CardPaymentStyled>
        <CardNumberElement options={cardStyle} />
      </CardPaymentStyled>
      <CardPaymentStyled>
        <CardExpiryElement options={cardStyle} />
      </CardPaymentStyled>
      <CardPaymentStyled>
        <CardCvcElement options={cardStyle} />
      </CardPaymentStyled>

      <LoadingButton
        color="success"
        size="large"
        loading={processing}
        loadingPosition="start"
        startIcon={<PaymentIcon />}
        variant="contained"
        fullWidth
        type="submit"
      >
        Pagar
      </LoadingButton>
    </form>
  );
};



const CheckoutTransfer = () => {
  return (
    <Grid container spacing={6}>
      <Grid item md={12}>
        <Typography variant="body2">
          <b>
            Para activar el plan seleccionado, realiza los siguientes pasos:
          </b>
          <br />
          <b>1.</b> Realiza un depósito o transferencia por el monto del plan a
          cualquiera de las siguientes cuentas.
          <br />
          <b>2.</b> Si tu pago es en quetzales, toma el tipo de cambio del día
          del banco seleccionado.
          <br />
          <b>3.</b> Una vez realizado el pago envía una imagen del comprobante
          al correo j@guatedatasuite.com
          <br />
          <b>4.</b> El equipo de Guate Data Suite corroborará la información
          enviada y activará tu plan en un lapso no mayor a 24 horas.
          <br />
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Typography align="center">
          <img
            src="https://res.cloudinary.com/guate-data-suite/image/upload/v1675407448/GUATEDATASUITE_DOCUMENTATION/image_r6zudy.png"
            alt="account"
            width={"65%"}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

const Payment = ({ open, setOpen, plan }) => {
  const { user } = useAuth();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  const [paymentMethod, setPaymentMethod] = useState("STRIPE");

  const handleChange = (event, newPaymentMethod) => {
    setPaymentMethod(newPaymentMethod);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth={true}
    >
      <Grid container>
        <Grid item xs>
          <DialogTitle>Datos de pago</DialogTitle>
        </Grid>
        <Grid item md={1}>
          <DialogActions>
            <Tooltip title="cerrar">
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Grid>
      </Grid>

      <DialogContent>
        <Typography variant="caption" color="text.secondary">
          Plan:
        </Typography>
        <Wrapper style={{ background: plan.background }}>
          <Grid container>
            <Grid item xs>
              <Typography variant="h6" align="left" color={plan.color}>
                {plan.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" align="right" color={plan.color}>
                {plan.price} / {plan.month}
              </Typography>
            </Grid>
          </Grid>
        </Wrapper>
        <Box sx={{ pb: 3 }}>
          <Typography variant="caption" color="text.secondary">
            Forma de pago:
          </Typography>
          <ToggleButtonGroup
            exclusive
            fullWidth
            color="info"
            value={paymentMethod}
            onChange={handleChange}
          >
            {payment_method_buttons.map((button) => (
              <ToggleButton value={button.value}>
                {button.logo}
                {button.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ fontStyle: "italic" }}
          >
            {paymentMethod === "STRIPE" &&
              "Pago con tarjeta de crédito de manera fácil y rápida (mejor opción)"}
            {paymentMethod === "RECURRENTE" &&
              "Se le redireccionara a una pagina externa para llenar el formulario y completar el pago correspondiente"}
          </Typography>
        </Box>
        {paymentMethod === "STRIPE" && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm setOpen={setOpen} stripe_user={user.stripe_data} plan_code={plan.plan} />
          </Elements>
        )}
        {paymentMethod === "TRANSFERENCIA" && <CheckoutTransfer />}
      </DialogContent>
    </Dialog>
  );
};

export default Payment;
