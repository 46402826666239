import axios from "./axios.default"


export const getDeptos = async () => {

    return await axios.get(`/events_service/current_by_dep`)
    .then((res) => {
        return res.data
    });
}

export const getBuyerEntity = async () => {
    return await axios.get(`/events_service/current_by_entity`)
    .then((res) => {
        return res.data
    });
} 



