import axios from "./axios"

/* EVENTOS VIGENTES  */
export const getModality = async () => {
    return await axios.get(`/events_service/modality/`)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return []
    });
}
export const getCategory = async () => {
    return await axios.get(`/events_service/category/`)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return []
    });
}
export const getDepartment = async () => {
    return await axios.get(`/events_service/department/`)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return []
    });    
}

export const getParentsEntities = async () => {
    
    return await axios.get(`/events_service/parent_entities/`)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return []
    });

}

export const getFilteredEvents = async(body, url) => {
    return await axios.post(`/events_service${url}`, body)
    .then((res) => {
        return res.data
    })
    .catch((error) => {
        console.log(error)
        return false
    });

}

/* EVENTOS ADJUDICADOS */

export const getBidReceptionType = async () => {
    return await axios.get(`/events_service/bid_type/`)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return []
    });
}

