import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import styled from "styled-components/macro";
import ReactPlayer from 'react-player';

import { Grid, Container, Card, CardContent } from "@mui/material";

import HelpSidebar from './Help.Sidebar';
import videos from "../home/videos";
import { useParams } from "react-router-dom";

const ContainerVideo = styled.div`
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 20px;

`;

const HelpVideo = () => {
    const { video } = useParams();

  return (
    <Container maxWidth="lg">
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item xs={12} md={12}>
          <Breadcrumb
            pagetitle={videos[video].title}
            title={videos[video].title}
            steps={[{ title: "Ayuda", to: "/help" }]}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item md={8}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <ContainerVideo>
                    <ReactPlayer
                        url={videos[video].url} 
                        width='100%'
                        height='100%'
                        controls
                        style={{position: 'absolute', top: 0, left: 0}}
                    />
                </ContainerVideo>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </Grid>
        <Grid item md={4}>
          <HelpSidebar />
        </Grid>

      </Grid>
    </Container>
  );
};
export default HelpVideo;