import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/* MUI Componentes */
import {
    Snackbar,
    IconButton,
    Alert,
} from "@mui/material";

import {
    Close as CloseIcon
}
    from "@mui/icons-material";


/*
    Snackbars provide brief notifications. The component is also known as a toast.
    Snackbars inform users of a process that an app has performed or will perform. 
    They shouldn't interrupt the user experience, and they don't require user input to disappear.

    Snackbars contain a single line of text directly related to the operation performed. 
    They may contain a text action, but no icons. You can use them to display notifications.

    Props
    message: message that you want to notify the user
    open: flag to show or not the snackbar
    setOpen: function to change state the flag

*/


const SnackbarComponent = ({ message, open, setOpen, severity, variant, vertical, horizontal}) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      
    return (
        <Snackbar
            key={new Date().getTime()}
            anchorOrigin={{
                vertical: vertical,
                horizontal: horizontal,
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            action={
                <Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            }
        >
            <Alert onClose={handleClose} severity={severity} variant={variant} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

SnackbarComponent.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}

export default SnackbarComponent
