import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import useNotify from "../../hooks/useNotify";

import InitPage from "../error404/InitPage";

import {
    getPost,
    delete_post,
} from '../../services/blog.service';

import {
    ArrowBackIos as ArrowBackIosIcon,
    Delete as DeleteIcon
  } from "@mui/icons-material";

import {
    Container,
    Card, 
    Button,
    Typography,
    LinearProgress,
    Grid
} from "@mui/material";

const Post = () => { 
    const { posturl } = useParams();
    const { user } = useAuth();
    const { notifyShow } = useNotify();

    const [dataHTML, setDataHTML] = useState(null)
    const [data, setData] = useState(null);
    const [notFound, setNotFound] = useState(null)
    

    const back = () => {
      window.history.back();
    };

    const deletePost = async () => {
        if (user?.is_superuser) {
            const res = await delete_post(data.id, data.title)
            if (res) { 
                return notifyShow(res.message, "error")
            }
        }
    } 

    useEffect(() => {
        const init = async () => {
            const response = await getPost(posturl)
            if (response.length> 0 ){
                const create = {
                    ...response[0], 
                    creation_date: moment(response[0]['creation_date']).utc().format('dddd, MMM D, YYYY'),
                }
                setData(create)
                setDataHTML({ __html: response[0]['content']});
            } else {
                setNotFound(true);
            }
            
        };
        init();
    }, [])

    return (
        <Container maxWidth="md">
            <Card sx={{ px: 16, py:12 }}>
                <Button 
                    size="small" 
                    startIcon={<ArrowBackIosIcon />} 
                    color="info"
                    onClick={back}
                >
                    Regresar al blog
                </Button>
                {!notFound? (
                    <>
                        {user?.is_superuser && (
                            <Button 
                                sx={{ mx:6 }}
                                size="small" 
                                startIcon={<DeleteIcon />} 
                                color="error"
                                onClick={deletePost}
                            >
                                Eliminar post
                            </Button>
                        )}
                        
                        <br /><br />
                        {dataHTML? (
                            <> 
                                <Typography variant="caption">{data.creation_date}</Typography>
                                {dataHTML && (
                                    <div dangerouslySetInnerHTML={dataHTML}></div>
                                )}
                            </>
                        ): 
                        (<LinearProgress/>)}
                    </>
                ):(
                    <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    height={400}
                    >
                        <Typography variant="h1" align="center">404 ERROR <br/>Publicación no encontrada</Typography>
                    </Grid>
                )}
               
            </Card>
        </Container>

    )

} 

export default Post
