import React, { useState } from 'react'
import styled from "styled-components/macro";
import { VectorMap } from '@south-paw/react-vector-maps';

import guatemala from './guatemala.json';




const getDepto = (name) => {

  switch (normalizeStr(name)) {
    case "quiche":
      return "gt-qc"
    case "zacapa":
      return "gt-za"
    case "jutiapa":
      return "gt-ju"
    case "jalapa":
      return "gt-ja";
    case "chiquimula":
      return "gt-cq"
    case "izabal":
      return "gt-iz"
    case "santa rosa":
      return "gt-sr"
    case "el progreso":
      return "gt-pr"
    case "totonicapan":
      return "gt-to"
    case "solola":
      return "gt-so"
    case "sacatepequez":
      return "gt-sa"
    case "suchitepequez":
      return "gt-su"
    case "guatemala":
      return "gt-gu"
    case "escuintla":
      return "gt-es"
    case "chimaltenango":
      return "gt-cm"
    case "alta verapaz":
      return "gt-av"
    case "san marcos":
      return "gt-sm"
    case "retalhuleu":
      return "gt-re"
    case "quetzaltenango":
      return "gt-qz"
    case "peten":
      return "gt-pe"
    case "huehuetenango":
      return "gt-hu"
    case "baja verapaz":
      return "gt-bv"
    default:
      return "gt-gu"
  }

}

const getColor = (quantity) => {
  if (quantity <= 0) {
    return '#d7dfff'
  }
  if (quantity <= 10) {
    return '#b9beff'
  }
  else if (quantity <= 20) {
    return '#979eff'
  }
  else if (quantity <= 40) {
    return '#6d80ff'
  }
  else if (quantity <= 60) {
    return '#4964ee'
  }
  else if (quantity <= 80) {
    return '#364bce'
  }
  else if (quantity < 100) {
    return '#2032ae'
  }
  else if (quantity >= 100) {
    return '#001a8f'
  }

}


const PrintMap = (deptos, disclaimer) => {

  const [quantity, setQuantity] = useState("");
  const [depto, setDepto] = useState("");

  
  const layerProps = {
    onMouseEnter: ({ target }) => {

      setDepto(target.attributes.name.value)
      setQuantity(0)
      var buscado = normalizeStr(target.attributes.name.value)
      let obj = deptos.find(o => normalizeStr(o.name).includes(buscado));
      if (obj) {
        setQuantity(obj.count)
      }
    }
  };

  let text = ""

  deptos.forEach(depto => {


    text += `&[id="${getDepto(depto.name)}"] {
      fill: ${getColor(depto.count)}
    }`
    return 0;
  });


  const MapContainer = styled.div`
  text-align: center;
  svg {
    width: 95%;
    stroke: #fff;
    // All layers are just path elements
    path {
      fill: rgba(171, 183, 183, 1);
      cursor: pointer;
      outline: none;
    
      // When a layer is hovered
      &:hover {
        fill: rgba(70, 125, 200, 0.8);
      }
      // When a layer is focused.
      &:focus {
        fill: rgba(70, 124, 200, 0.71);
      }
      ${text}
    }
  }
  }

`;
  return (
    <>
      <p  style={{ textAlign: "center" }}><b>Eventos vigentes por departamento</b> </p>  
      <MapContainer>
        <VectorMap {...guatemala} layerProps={layerProps} />
      </MapContainer>
      {
      depto !== ""
        ? <p key={depto} style={{ textAlign: "center"}}> La cantidad de eventos { disclaimer } en <b>{depto}</b> es de <b>{quantity}</b> </p>
        : <p key="1" style={{ textAlign: "center"}}>Posiciónate sobre un departamento para ver la cantidad de eventos vigentes</p>
      }
    </>
  );

}

const normalizeStr = (text) => {
  return text.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const Map = ({ data }) => {
  return (
    <>
      {
        PrintMap(data)
      }
    </>
  )
}

export default Map
