import axios from "./axios.default"

export const support_help = async (body) => {
    return await axios.post(`/business/support/help`, body)
    .then(response => { return response.data })
    .catch(error=> { return console.log(error) })
}

export const support_contact = async (body) => {
    return await axios.post(`/business/support/contact`, body)
    .then(response => { return response.data })
    .catch(error=> { return console.log(error) })
}

export const cloudinary_upload  = async (url, body) => {
    return await axios.post(url, body)
        .then(res => { return res.data.url })
        .catch(error => { return console.log(error)})
}