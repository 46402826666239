import { createContext, useState } from "react";
import SnackbarComponent from "../components/Alerts/Snackbar";

const NotifyContext = createContext(null);

function NotifyProvider({ children }) {
  const [alertMessage, setAlertMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");
  const [variant, setVariant] = useState("standard");
  const [position, setPosition] = useState(["button","right"]);

  const notifyShow = async (message, severity, variant, position) => {
    setAlertMessage(message);
    setSeverity(severity);
    variant? setVariant(variant) : setVariant("filled");
    position? setPosition(position) : setPosition(["button","right"])

    setOpenSnack(true);
  };

  return (
    <NotifyContext.Provider value={{ notifyShow }}>
      {children}
      {openSnack && (
        <SnackbarComponent
          message={alertMessage}
          open={openSnack}
          setOpen={setOpenSnack}
          severity={severity}
          variant={variant}
          vertical={position[0]}
          horizontal={position[1]}
        />
      )}
    </NotifyContext.Provider>
  );
}

export { NotifyContext, NotifyProvider };
