import { createContext, useEffect, useReducer } from "react";

//Services
import {
    getBidReceptionType,
    getModality,
    getCategory,
    getDepartment,
    getParentsEntities,
} from '../services/busqueda.service'

const INITIALIZE = "INITIALIZE";

const initialState = {
    category: [],
    modality: [],
    depto: [],
    entity: [],
    bids: [],
    myState: "NOINITIALIZE"
};

const FilterReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                category: action.payload.category,
                modality: action.payload.modality,
                depto: action.payload.depto,
                entity: action.payload.entity,
                bids: action.payload.bids,
                myState: action.payload.myState
            };
        default:
            return state;
    }
};


const FilterContext = createContext(null);

function FilterProvider({ children }) {
    const [state, dispatch] = useReducer(FilterReducer, initialState);

    const user = localStorage.getItem("user")
    //console.log(initialState)
    useEffect(() => {
        const initialize = async () => {
            try {
                if (initialState.myState === "NOINITIALIZE" && user) {
                    var modality = await getModality();
                    var category = await getCategory();
                    var depto = await getDepartment()
                    var entity = await getParentsEntities()
                    var bids = await getBidReceptionType()
                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            category: category,
                            modality: modality,
                            depto: depto,
                            entity: entity,
                            bids: bids,
                            myState: "INITIALIZE"
                        }
                    });
                }

            } catch (err) {
                console.log(err)
            }
        };

        initialize();
    }, [user]);


    return (
        <FilterContext.Provider
            value={{
                ...state
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}

export { FilterContext, FilterProvider };
