
import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components/macro";
import { Field } from "formik";

import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";


import { FormikForm, CustomInputComponent } from "../../components/Form/Formik/FormikForm"

//Mui components
import {
    Grid,
    Paper,
    Typography,
    Button
} from "@mui/material";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Link from '@mui/material/Link';

import { NavLink, useNavigate } from "react-router-dom";
import logo from '../../vendor/favicon.svg'

// validation schemas

import { emailSchema } from "../../utils/validationSchemas";
import useNotify from "../../hooks/useNotify";

// Styled components
const Wrapper = styled(Paper)`
padding: ${(props) => props.theme.spacing(6)};
`;

const Brand = styled(Paper)`
  color: #5271ff;
  background-color: rgba(255, 255, 255, 0);
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  height: 120px;
  width: 370px;
  &:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  text-decoration: none;
    
`;

const BrandIcon = styled.div`
  margin-right: ${(props) => props.theme.spacing(6)};
  margin-top: -2px;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
`;

/**
 * Default values ​​of the form, these values ​​are not valid and are not sent until the required fields are filled.
 */
const initialValues = {
    email: "",
    password: "",
    submit: false,
}

/*
    Object to validate the entry of the elements, 
    that are required, that meet requirements such as size, the passwords must match, etc.
*/
const validationSchema = Yup.object().shape({
    email: emailSchema,
})

/**
 * This view is used to create an account in the application
 * 
 */

const ResetPassword = () => {
    const [open, setOpen] = React.useState(false);
    const {notifyShow} = useNotify();
    const { resetPassword } = useAuth();
    const navigate = useNavigate();

    const handleClickOpen = (values) => {
        setOpen(true);
        setEmail(values.email)
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [KeyWord, setKeyWord] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        setKeyWord("")
    }, [])

    const _handleTextFieldChange = (e) => {
        setKeyWord(e.target.value)
    }

    

    const resetPasswordFunction = async () => {
        const result = await resetPassword(email);
        
        if (result.error) notifyShow("el correo no fue correcto, inténtelo de nuevo", "error");

        if (result.data) {
            notifyShow("Contraseña reiniciada correctamente. Recibirás un correo electrónico con la nueva contraseña.", "success")
            setTimeout(() => {
                navigate("/auth/sign-in");
            }, 1000)
        }

    }

    return (
        <Fragment>
            <Brand component={NavLink} to="/" className="fontFamily"  >
                <Grid container alignItems="center" spacing={12}>
                    <Grid item md={2} xs={2} sm={2}>
                        <BrandIcon style={{backgroundImage: `url(${logo})` }}></BrandIcon>
                    </Grid>
                    <Grid item md >
                     GUATE DATA SUITE
                    </Grid>

                </Grid>
            </Brand>
            <Wrapper>
            
            <Typography component="h1" variant="h4" align="center" gutterBottom>
                    Reinicio de contraseña de usuario
                </Typography>
                <Typography component="h2" variant="body2" align="center">
                 Ingresa el correo con el que creaste tu cuenta.
                </Typography>
                <br />
                { /* 
                Initialize the form with the initial values ​​and the validation scheme
                */
                }
                <FormikForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    functionToExecute={handleClickOpen}
                    buttonProps={{ fullWidth: true }}
                    titleButton="Reiniciar contraseña"
                >
                    <Grid container >
                        <Field type="email" name="email" label="Correo electrónico" as={CustomInputComponent} />
                    </Grid>
                    <br />

                </FormikForm>
                <br />
                
                <Typography component="h2" variant="body2" align="center">
                ¡Precaución! Si reinicias tu contraseña no podrás ingresar con la contraseña anterior. Si no estás seguro regresa a iniciar sesión. 
                <br />
                <Link key="register" sx={{color: '#5271ff'}} href="/auth/sign-in" > regresa a iniciar sesión </Link>
                </Typography>
            </Wrapper>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Confirmar el reinicio de contraseña</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    ¡Precaución! Si reinicias tu contraseña no podrás ingresar con la contraseña anterior.
                </DialogContentText>
                <DialogContentText>
                    Si deseas proseguir por favor escribe en el cuadro de texto la palabra “reiniciar”
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Confirmación"
                    type="text"
                    placeholder="reiniciar"
                    fullWidth
                    onChange={_handleTextFieldChange}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancelar</Button>
                    <Button onClick={resetPasswordFunction} disabled={KeyWord !== 'reiniciar'}>Continuar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default ResetPassword





