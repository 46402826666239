const videos = [
  {
    title: "Introducción a Compras Públicas GT",
    description:
      "¿Qué es Compras Públicas GT y cómo me puede ayudar a incrementar mis ventas en el mercado de entidades públicas?",
    url: "https://www.youtube.com/watch?v=qM5lGBVQZI0",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678736782/GUATEDATASUITE_MINIATURAS/PRONTO_liuclu.png'
  },
  {
    title: "Guatecompras en números",
    description:
      "Conoce el mercado de entidades públicas a través de un análisis desarrollado por Guate Data Suite",
    url: "https://www.youtube.com/watch?v=1Bj84o7w_gk",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678730932/GUATEDATASUITE_MINIATURAS/Guatecompras_en_n%C3%BAmeros_jfdkam.png'
  },
  {
    title: "Cómo buscar eventos vigentes",
    description:
      "Aprende a buscar y filtrar eventos vigentes de una manera rápida y eficiente.",
    url: "https://www.youtube.com/watch?v=vyVijaMB_Wg&t=639s",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678730939/GUATEDATASUITE_MINIATURAS/B%C3%BAsqueda_de_eventos_vigentes_ixtqb6.png'
  },
  /**
  {
    title: "Cómo analizar la base de datos histórica",
    description:
      "¿Quieres saber qué productos se venden en guatecompras? ¿Quiénes compran, a qué precio? Aprende a tomar decisiones informadas y optimizar tus ofertas. ",
    url: "https://youtu.be/PeyIFdcpuVI",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678736782/GUATEDATASUITE_MINIATURAS/PRONTO_liuclu.png'
  },
  */
  {
    title: "Cómo agregar seguidores y recibir notificaciones",
    description:
      "Configura los correos y los números de teléfono de tu equipo de trabajo para recibir notificaciones de los próximos eventos.",
    url: "https://youtu.be/PeyIFdcpuVI",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678736782/GUATEDATASUITE_MINIATURAS/PRONTO_liuclu.png'
  },
  {
    title: "Cómo agregar palabras clave",
    description:
      "Aprende a suscribirte a las palabras clave que tengan un alto potencial de demanda.",
    url: "https://www.youtube.com/watch?v=IpmQ1AZpk68",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678730939/GUATEDATASUITE_MINIATURAS/AGREGAR_PALABRAS_CLAVE_oafsdt.png'
  },
  /**
  {
    title: "Cómo analizar mis portafolios",
    description:
      "La funcionalidad de portafolios de suscripciones nos permite pronosticar en cuántos eventos participaremos al mes o al trimestre. Planifica mejor tu tiempo y recursos con esta información.",
    url: "https://youtu.be/PeyIFdcpuVI",
    mini: 'https://res.cloudinary.com/guate-data-suite/image/upload/v1678736782/GUATEDATASUITE_MINIATURAS/PRONTO_liuclu.png'
  },
   */
];

export default videos;
