import React from 'react'
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
    Grid,
    Container,
    Typography as MuiTypography,
    Button as MuiButton,
    Hidden,
} from "@mui/material";

const columns = [
    {name: "UNIDAD COMPRADORA"},
    {name: "DEPARTAMENTO"},
    {name: "NUMERO DE EVENTOS VIGENTES"},
]

const ColumnsTitle = styled.div`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    padding: 18px 10px 18px 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
`;
const Typography = styled(MuiTypography)(spacing);

const Title = styled(Typography)`
  padding: 40px;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
  }
  span {
    color: #000;
  }
`;

const ColumnsData = styled.div`
    background-color: #fff;
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;
    border-style: solid;
    border-color: #b3b3b3;
    border-width: 1px;
    &:hover {
        background-color: #b0b0b0
    }
`;

const Item = styled(Typography)`
    ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: .8rem;
    }
    ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: .8rem;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
    font-size: .8rem;
    }
    span {
    color: #000;
    }
`;

const Button = styled(MuiButton)`
  width: 300px;
  font-size: 1.2rem;
  font-weight: 4rem;
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
`;



const TableHome = ({row, onClickFunction}) => {
    return(
        <Container maxWidth="lg"  align="center">
            <Title>Top 5 unidades compradoras por eventos vigentes</Title>
        <ColumnsTitle >
        <Grid container >
            
            <Grid item  md={6} sx={6} align="left">
                <Item>
                {columns[0].name}
                </Item>
            </Grid>

            <Hidden mdDown>
            <Grid item  md={3} sx={3}>
                <Item>
                {columns[1].name}
                </Item>
            </Grid>
            <Grid item  md={3} sx={3}>
                <Item>
                {columns[2].name}
                </Item>
                
            </Grid>
                
            </Hidden>
            
        </Grid>
           
        </ColumnsTitle>
        
        {row.map((data) => (
            <ColumnsData>
                <Grid container >
                    <Grid item  md={6} sx={12} align="left" >
                        <Item style={{fontWeight: 'bold'}}>
                            {data.buyerUnity}
                        </Item>
                        <Item>
                            {data.buyerEntity}
                        </Item>
                    </Grid>
                    <Hidden mdDown>
                    <Grid item  md={3} sx={12} alignItems="center"   mdDown>
                        
                        <Item>
                        {data.department}
                        </Item>
                        
                    </Grid>
                    <Grid item  md={3} sx={12}  mdDown>
                    <Item>
                        {data.quantity}
                    </Item>
                    </Grid>

                    </Hidden>
                    
                    
                </Grid>
            </ColumnsData>
        ))}
         <Button
                ml={2}
                color="primary"
                variant="contained"
                href="/auth/sign-up"
                target="_blank"
              >
                Conoce más
              </Button>
        </Container>
    
    )
}


export default TableHome;