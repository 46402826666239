import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Grid, Typography, Button as MuiButton} from "@mui/material";

import { spacing } from "@mui/system";

const Wrapper = styled.div`
    ${spacing};
    position: relative;
    cursor: pointer;
    background: #5271ff;
    color: ${(props) => props.theme.palette.common.black};
    &:hover {
        color: ${(props) => props.theme.sidebar.color};
    }
`;
const GridPer = styled(Grid)`
    text-align: center;
    padding: 10px;
`;

const BrandS = styled(Typography)`
    color: #ffff;
    font-size: ${(props) => props.theme.typography.h4.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-family: ${(props) => props.theme.typography.fontFamily};
    text-decoration: none;
`;

const Brand = ({tittle, link}) => {
    const toLink = () => {
        window.open('https://cursos.guatedatasuite.com/prensa', "_blank")
        return null;
    }

    return (
        <Wrapper onClick={() => toLink()}>
            <GridPer >
                <BrandS component={NavLink} to="/" className="fontFamily">
                    Prensa
                </BrandS>
            </GridPer>
        </Wrapper>
    )
}


export default Brand;
