import axiosdefault from "./axios.default"
import axios from "./axios";

export const getPosts = async () => {

    return await axiosdefault.get(`/business/posts`)
    .then((res) => {
        return res.data
    });
}

export const getPost = async (url) => {

    return await axiosdefault.get(`/business/post/${url}`, )
    .then((res) => {
        return res.data
    });
}

export const create_post = async (id, title, body) => {
    return await axios
      .post(`/business/post/${title}/${id}`, body)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error.message);
        return { message: "error" };
      });
  };

  export const delete_post = async (id, title) => {
    return await axios
      .delete(`/business/post/${title}/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error.message);
        return { message: "error" };
      });
  };