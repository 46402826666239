import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../../utils/GlobalStyle";


import AppBar from "./AppBar";

import {
    Grid,
    Container,
    Typography
} from "@mui/material";
import { padding } from "polished";


const TermsAndConditions = () => {
    


    const Root = styled.div`
    display: flex;
    min-height: 100vh;
    `;

    const Header = styled.div`
    padding: ${(props) => props.theme.spacing(6)} 0;
    `;

    const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    `;

    const Wrapper = styled.div`
  ${spacing};
`;

    return (
        <Root>
        { /* 
            these components provide the theme with material design styles
            is similar to calling the file directly
            <link src = "assets/css/globalstyle.css">
        */ }
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
            <AppBar />
            <Wrapper py={20}>
                <Container>
                    <Header>
                        <Typography variant="h1" gutterBottom align="center">
                            Términos y condiciones
                        </Typography>
                    </Header>
                    <Grid container >
                    <p>Al acceder y utilizar este sitio web "guatedatasuite.com", usted acepta cumplir y estar sujeto a los siguientes Términos y Condiciones. Si no está de acuerdo con estos Términos, debe abandonar el Sitio inmediatamente.</p>

                    <h2>1. Aceptación de los Términos</h2>
                    <p>Al acceder y utilizar este sitio web "guatedatasuite.com", usted acepta cumplir y estar sujeto a los siguientes Términos y Condiciones. Si no está de acuerdo con estos Términos, debe abandonar el Sitio inmediatamente.</p>

                    <h2>2. Descripción del Servicio</h2>
                    <p>El Sitio proporciona servicios de scraping de datos públicos disponibles en sitios web gubernamentales relacionados con eventos públicos. Toda la información recopilada está disponible públicamente y se recopila conforme a las leyes y regulaciones aplicables.</p>

                    <h2>3. Uso Aceptable ‎  </h2>
                    <p>El usuario se compromete a utilizar el Servicio únicamente para fines lícitos y de acuerdo con estos Términos. Queda prohibido el uso del Servicio para:</p>
                    <ul>
                        <li>Obtener datos para actividades ilegales o fraudulentas.</li>
                        <li>Violación de derechos de propiedad intelectual.</li>
                        <li>Cualquier forma de discriminación, difamación, o contenido ofensivo.</li>
                    </ul>

                    <div style={{paddingRight: '100%'}}></div>
                    <h2>4. Propiedad Intelectual</h2>
                    <p>Todos los derechos, títulos e intereses en y sobre el Sitio y el Servicio, incluidos los contenidos, características y funcionalidades (incluyendo pero no limitado a información, software, texto, visuales, imágenes, video y audio, y su diseño, selección y disposición), son propiedad del Sitio, sus licenciantes u otros proveedores de dicho material y están protegidos por leyes de derechos de autor, marcas, patentes, secretos comerciales y otras leyes de propiedad intelectual.</p>

                    <h2>5. Limitación de Responsabilidad</h2>
                    <p>El Sitio no será responsable de ningún daño directo, indirecto, incidental, especial o consecuente que resulte del uso o la imposibilidad de uso del Servicio, incluyendo pero no limitado a la obtención de datos incorrectos o incompletos.</p>

                    <h2>6. Enlaces a Terceros</h2>
                    <p>El Sitio puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente para su conveniencia. El Sitio no tiene control sobre el contenido de estos sitios web y no asume responsabilidad alguna por ellos o por cualquier pérdida o daño que pueda surgir de su uso.</p>

                    <h2>7. Modificaciones de los Términos</h2>
                    <p>Nos reservamos el derecho a modificar o actualizar estos Términos en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en el Sitio. Su uso continuado del Sitio después de la publicación de los términos revisados significa que acepta y está de acuerdo con los cambios.</p>

                    <h2>8. Ley Aplicable y Jurisdicción</h2>
                    <p>Estos Términos se regirán e interpretarán de acuerdo con las leyes del país en el que se encuentra el propietario del Sitio, sin dar efecto a ninguna disposición o regla de elección de leyes. Cualquier disputa relacionada con estos Términos se resolverá exclusivamente en los tribunales del país mencionado.</p>

                    <h2>9. Contacto</h2>
                    <div style={{paddingRight: '100%'}}></div>
                    <p>Para cualquier consulta sobre estos Términos, por favor póngase en contacto con nosotros a través de <a href="mailto:contact@guatedatasuite.com">contact@guatedatasuite.com</a>.</p>

               </Grid>
                </Container>
            </Wrapper>
        </AppContent>
    </Root>
    )
}

export default TermsAndConditions;