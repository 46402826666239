import axios from "./axios.default";

export const signUpService = async(body) => {
    return await axios.post("business/user-account/", body
    ).catch((error) => console.log( error ) );

    /*const resp = await fetch(url, {
        method:'POST',
        body: body
    })
    const fetchJSON = await resp.json();
    return fetchJSON;*/ 


}

export const signInService = async (email, password) => {
    return await axios.post("/business/user-auth/", {
        email,
        password,
    })
    .catch((error) => {
        return {"error": error.message}
    });
}

export const resetPasswordService = async (email, email_encrypt, security_access) => {
   const data = {"email": email, "security_key": email_encrypt, "security_access": security_access}
    return await axios.post("/business/change-password", data)
    .catch((error) => {
        return {"error": "error"}
    });
}



export const refreshTokenService = async (body) => {
    return await axios.post("/business/extend_session/", {'refresh':body})
    .then((res) => {
        return res.data
    }).catch((error) => console.log( error ));
}