import React, {useState, useEffect, Fragment} from 'react'
import moment from "moment";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";


import { getPosts } from '../../services/blog.service';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    CardActions,
    CardActionArea
} from "@mui/material";

const Wrapper = styled.div`
  text-align: center;
  padding: 70px 0;
`;

const Posts = () => {
    const [data, setData] = useState(null);
    const path = window.location.pathname;
    const he = require('he');

    function obtenerPrimerParrafoDespuesDeSaltosDeLinea(texto) {
        const textoSinDivs = texto.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '');
        var lineas = textoSinDivs.split('\n');
      
        var primerParrafo = null;
        for (var i = 0; i < lineas.length; i++) {
          var linea = lineas[i].trim(); // Eliminar espacios en blanco al inicio y final de la línea
          if (linea !== "") {
            primerParrafo = linea;
            break;
          }
        }
        return primerParrafo.replace(/<\/?[^>]+(>|$)/g, "");
    }

    const limitarTexto = (texto, caracteres) => {
        if (texto.length <= caracteres) {
          return texto;
        } else {
          return texto.substring(0, caracteres) + "...";
        }
      }

    // Función para obtener el texto sin el primer párrafo después de los saltos de línea
    function obtenerTextoSinPrimerParrafo(texto) {
        const content = texto.replace(/<[^>]+>.*?<\/[^>]+>/, "")
        const content_html = content.replace(/<\/?[^>]+(>|$)/g, "");
        const contentClean = content_html.replace(/&nbsp;|\n/g, " ");
        return limitarTexto(contentClean.trim(), 170)
    }

    useEffect(() => {
        const init = async () => {
            const res = await getPosts()
            const hola = res.map(post => { 
                return {
                    ...post, 
                    heading: he.decode(obtenerPrimerParrafoDespuesDeSaltosDeLinea(post.content)), 
                    content: obtenerTextoSinPrimerParrafo(post.content),
                    creation_date: moment(post.creation_date).utc().format('dddd, MMM D, YYYY')
                }})
            console.log(hola)
            setData(hola)

        };
        init();
    }, [])
    return data && (
        <Fragment>
            <Container >
                <Wrapper>
                    <Typography variant="h6" color="secondary">
                            Blog
                    </Typography>
                    <Typography variant="h1" >
                        Lo ultimo acerca de GuateDataSuite
                    </Typography>
                </Wrapper>
            </Container>
            <Container >
                <Grid container spacing={12}>
                    {data.reverse().map((post) => (
                        <Grid item md={6}>
                            <Card fullWidth>
                            <CardActionArea to={`${path}/${post.title}`} component={NavLink} >
                                <CardContent sx={{ minHeight: '165px' }}>
                                    <Typography gutterBottom variant="h3" component="div">
                                        {post.heading}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {post.content}
                                    </Typography>
                                </CardContent>
                                <CardActions >
                                    <Typography variant="caption" color="text.secondary">
                                        {post.creation_date}
                                    </Typography>
                                </CardActions>
                            </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Fragment>)
}

export default Posts