import React from "react";
import styled from "styled-components/macro";

import {
  Button,
  CardActions,
  CardContent,
  Grid, Container,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { Link } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Price = styled(Box)({
  textAlign: "center",
  marginTop: "50px",
  marginBottom: "50px"
})

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const Title = styled(Typography)`
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.7rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.2rem;
  }
  span {
    color: #000;
  }
`;

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const Description = styled(Box)({
  textAlign: "center",
  height: "30px",
})

const Specs = styled(Box)({
  textAlign: "center",
  height: "250px",
})


//This component shows the prices

const Pricing = () => {


  const plans = [
    {
      title: "Proveedor Estandar",
      desc: "Recomendado para explorar palabras clave",
      price: "Q550/mes",
      alt: "Un pago anual de Q 4,800",
      specs: [
        "  Notificaciones de eventos en tiempo real Ilimitadas",
        "  Búsquedas avanzadas de eventos activos ilimitadas",
        "  10 suscripción y rastreo de palabras clave",
        "  3 usuarios para recibir notificaciones",
        "  Soporte ilimitado",
      ],
      background: "#f9f9f9",
      colorButton: 'primary',
    },
    {
      title: "Proveedor Avanzado",
      desc: "Para pequeñas empresas y comerciantes individuales",
      price: "Q1400/mes",
      alt: "Un pago anual de Q 15,000",
      featured: true,
      specs: [
        "  Notificaciones de eventos en tiempo real Ilimitadas",
        "  Búsquedas avanzadas de eventos activos ilimitadas",
        "  Búsquedas avanzada de precios ilimitadas",
        "  10 suscripción y rastreo de palabras clave",
        "  3 usuarios para recibir notificaciones",
        "  Soporte ilimitado",
      ],
      background: "#5271ff",
      colorButton: 'primary',
      color: 'white'
    },
    {
      title: "Proveedor Experto",
      desc: "Para catálogos extensos y grupos de trabajo diversos",
      price: "Q3200/mes",
      alt: "Un pago anual de Q 30,000",
      specs: [
        "  Notificaciones de eventos en tiempo real Ilimitadas",
        "  Búsquedas avanzadas de eventos activos ilimitadas",
        "  Búsquedas avanzada de precios ilimitadas",
        "  50 suscripción y rastreo de palabras clave",
        "  10 usuarios para recibir notificaciones",
        "  Soporte ilimitado",
      ],
      background: "#fec601",
      colorButton: 'info',
    },
  ]


  return (

    <Wrapper py={10} id={"chartcontainer"}>
      <Container  >
        <Header>
          <Title align="center">
            Conoce nuestros planes
          </Title>
        </Header>
        <Grid container justifyContent="center">
          {
            plans.map((item, idx) => (
              <Grid key={idx} item p={4} md sx={12}>
                <Grid style={{background: item.background, color: item.color }} component={Card} p={10} variant="outlined">
                  <CardHeader title={item.title} titleTypographyProps={{ align: "center" }} pb={0} pt={2} />
                  <CardContent>
                    <Description>
                      {item.desc}
                    </Description>
                    <Price>
                      <Typography
                        variant="h1"
                        align="center"
                      >
                        {item.price}
                      </Typography>
                      <Typography
                        variant="string"
                        align="center"
                      >
                        {item.alt}
                      </Typography>
                    </Price>
                    <Specs>
                    

                      {
                        item.specs.map((spec, id) => (
                          <>
                          <Typography align="center" > 
                            <CheckIcon sx={{ fontSize: 13}} />
                            {spec}
                            <br />

                            </Typography>
                          </>
                        ))
                      }
                    
                    </Specs>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth component={Link} to="/pricing-details" variant={'contained'} color={item.colorButton}>
                      Conoce más
                    </Button>         
                  </CardActions>

                </Grid>
              </Grid>
              
            ))
          }
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Pricing;
