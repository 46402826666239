import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

//CUSTOM HOOKS
import useNotify from "../../hooks/useNotify";

import {
  Button,
  Grid,
  Container,
  CircularProgress,
  CardContent,
  Typography,
} from "@mui/material";

import { support_contact } from '../../services/support.service';

import { Formik } from "formik";
import { Box, spacing } from "@mui/system";
import { Element } from "react-scroll";

import TextField from "../../components/Form/Textfields/Textfield";
import Alert from "../../components/Alerts/Alert";
import axios from "axios";

const Header = styled.div``;

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
  ${(props) => props.theme.breakpoints.up("sm")} {
    widht
  }
`;

const Title = styled(Typography)`
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.7rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.2rem;
  }
  span {
    color: #000;
  }
`;

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
  company: "",
  job: "",
};

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  email: Yup.string()
    .email("Debe ingresar un correo válido")
    .max(255)
    .required("El correo es requerido"),
  phone: Yup.string()
  .matches(/^\d{8}$/, 'El número debe tener exactamente 8 dígitos.')
  .required('Campo requerido'),
});

//This component shows the prices

const Contact = () => {
  const { notifyShow } = useNotify();

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      
      const message_slack = await support_contact(values)
      
      notifyShow(message_slack?.message, "success")
      await timeOut(1000);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Wrapper py={10} id={"chartcontainer"}>
      <Element name="contact" className="element">
        <Container maxWidth="md">
          <Header>
            <Title align="center">Contáctanos</Title>
          </Header>
          <Grid container justifyContent="center">
            <CardContent>
              <Formik
                initialValues={
                  initialValues
                } /*the initial values ​​of the form will be those that come in the follower object */
                validationSchema={validationSchema} /*form field validations*/
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <>
                    {status && status.sent && (
                      <Alert
                        severity="success"
                        my={3}
                        variant="outlined"
                        show={true}
                        text="Nos pondremos en contacto lo antes posible"
                      />
                    )}

                    {isSubmitting ? (
                      <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          name="name"
                          label="Nombre*"
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />

                        <TextField
                          name="email"
                          label="Correo electrónico*"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />

                        <TextField
                          name="phone"
                          label="Teléfono*"
                          value={values.phone}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />

                        <TextField
                          name="company"
                          label="Nombre de la compañía"
                          value={values.company}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                        <TextField
                          name="job"
                          label="Puesto"
                          value={values.job}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                        <TextField
                          name="message"
                          label="Mensaje"
                          value={values.message}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                        <Grid
                          container
                          justifyContent="center"
                          style={{ paddingTop: "30px" }}
                        >
                          <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            Enviar
                          </Button>
                        </Grid>
                      </form>
                    )}
                  </>
                )}
              </Formik>
            </CardContent>
          </Grid>
        </Container>
      </Element>
    </Wrapper>
  );
};

export default Contact;
