const terminology = [  
    {
      word: 'Guatecompras',
      meaning: 'Sistema de Información de Contrataciones y Adquisiciones del Estado, administrado por el Ministerio de Finanzas Públicas, creado para brindar mayor transparencia a las compras públicas.'
    },
    {
      word: 'Evento',
      meaning: 'Proceso por el cual una institución realiza una compra o adquisición. También llamado concurso en algunas ocasiones'
    },
    {
      word: 'Nog',
      meaning: 'Número de Operación Guatecompras. Es el número que identifica un evento.'
    },
    {
      word: 'Evento vigente',
      meaning: 'Es un evento el cual su fecha y hora de cierre no ha caducado. Los proveedores inscritos en el Registro General de Adquisiciones del Estado (RGAE) pueden presentar sus ofertas en eventos vigentes'
    },
    {
      word: 'Adjudicación',
      meaning: 'Acto jurídico donde se le atribuye una compra a una persona individual o jurídica a través de un evento.'
    },
    {
      word: 'Evento adjudicado',
      meaning: 'Evento no vigente el cual ha sido adjudicado a una o varias personas individuales o jurídicas.'
    },
    {
      word: 'Modalidad',
      meaning: 'Establece el monto máximo en quetzales y los requisitos necesarios para participar en un evento. Existen distintos tipos de modalidades.'
    },
    {
      word: 'Categoría',
      meaning: 'Clasificación a la que se asigna un evento según los bienes o servicios que solicita. '
    },
    {
      word: 'Entidad compradora',
      meaning: 'Institución u organización que utiliza fondos públicos para realizar sus compras y adquisiciones. '
    },
    {
      word: 'Unidad compradora',
      meaning: 'Unidad que ejecuta las compras de una entidad. Una entidad puede tener una o más unidades compradoras. '
    },
    {
      word: 'Proveedor',
      meaning: 'Persona individual o jurídica que participa en eventos a través de guatecompras. '
    },{
      word: 'Oferta',
      meaning: 'Cotización o propuesta presentada por un proveedor.  '
    },
    {
      word: 'Palabra clave',
      meaning: 'En el contexto de Guate Data Suite, una palabra clave es un término de búsqueda que muestra eventos relacionados a la misma. '
    },
    {
      word: 'Búsqueda por descripción de evento',
      meaning: 'Búsqueda de palabras clave en la descripción de un evento. '
    },
    {
      word: 'Búsqueda por listado de tipos de productos',
      meaning: 'Búsqueda de palabras clave en el apartado “tipos de producto” de un evento.'
    },
    {
      word: 'Suscripción',
      meaning: 'Una vez seleccionada una palabra clave y demás filtros, una suscripción notifica a un usuario cuando un evento que cumple con las especificaciones definidas es publicado en guatecompras.'
    },
    {
      word: 'Seguidor',
      meaning: 'Un usuario con un correo o un número de teléfono que se asigna a una suscripción. El seguidor recibe notificaciones de todas las suscripciones que encuentren nuevos eventos.'
    },
  ];
  
  export default terminology;