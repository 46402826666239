import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import { Formik, Form, ErrorMessage } from 'formik';

// Template components
import Alert from "../../Alerts/Alert"
import TextField from "../Textfields/Textfield"

import {
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    InputAdornment 
} from "@mui/material";

import { 
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

//MUI COmponents
import {
    Box,
    Button as MuiButton,
    CircularProgress
} from "@mui/material";
// Styled components
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const PasswordInputComponent = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
        <>
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
                <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                    }
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    label="Password"
                    value={props.value}
                    {...props}
                />
            </FormControl>
            <ErrorMessage name={props.name} component="div" style={{ color: 'red' }} />
        </>
    );
}


const CustomInputComponent = (props) => {
    /**
     * This function returns a Textfield type object with the properties and functions required by the form
     */
    return (
        <>
            <TextField
                name={props.name}
                label={props.label}
                value={props.value}
                type={props.type}
                fullWidth
                variant="outlined"
                onBlur={props.onBlur}
                onChange={props.onChange}
                my={2}
                {...props}
            />
            <ErrorMessage name={props.name} component="div" style={{ color: 'red' }} />
        </>
    );
}


/* 
    Documentation
    This component is used to create custom forms using the Formik 
    library that provides some functionalities such as field validation, etc.
    initialValues:initial values ​​of the form
    validationSchema: validation scheme of the fields, such as lengths, if required, etc.
    handleSubmit:function that will be executed when the button is clicked
    children: form fields
    titleButton: button action title
*/

const FormikForm = ({ initialValues, validationSchema, functionToExecute, children, titleButton, buttonProps }) => {

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            await functionToExecute(values)
            await timeOut(1500);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
        } catch (error) {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, isSubmitting }) => (
                <>
                    {
                        isSubmitting ? (
                            <Box display="flex" justifyContent="center" my={6}>
                                <CircularProgress />
                            </Box>
                        ) :
                            (
                                <Form>
                                    {children}

                                    <Box display="flex"
                                        justifyContent="center"
                                        alignItems="center" >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            {...buttonProps || {size:"medium"} }
                                        >
                                            {titleButton}
                                        </Button>

                                    </Box>


                                </Form>
                            )
                    }
                    <br />
                    {errors.submit && (
                        <Alert mt={2} mb={3} variant="outlined" show={true} text={errors.submit} severity="warning" />
                    )}
                </>

            )}
        </Formik>
    )
}

FormikForm.propTypes = {
    initialValues: PropTypes.object.isRequired, 
    validationSchema: PropTypes.object.isRequired, 
    functionToExecute: PropTypes.func.isRequired, 
    children: PropTypes.node.isRequired, 
    titleButton: PropTypes.string.isRequired, 
    buttonProps: PropTypes.object
}


export { FormikForm, CustomInputComponent, PasswordInputComponent }
