import videos from '../home/videos';
import { NavLink } from "react-router-dom";
import { Abc, FlagCircle, QuestionAnswer, ChromeReaderMode, PlayCircleFilledWhite } from "@mui/icons-material";
import { Box, Card, CardActionArea, CardMedia, Container, Grid, Typography } from "@mui/material";

const HelpSidebar = () => {
    return (
    <Container maxWidth="xs">
        <Grid container >
        <Grid item md={12}>
        <CardActionArea to="/dashboard/docs/video/0" component={NavLink} style={{textDecoration: 'none'}}>
                    <Card sx={{p:2, mb:2 }} >
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                            <Box sx={{p:1, px: 9}}>
                                <FlagCircle sx={{ width: 48, height: 48}} />
                            </Box>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography component="div" variant="h6" noWrap sx={{ fontSize: '0.9rem'}}>
                                    {videos[0].title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    {videos[0].description}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    <PlayCircleFilledWhite fontSize="small"  />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </CardActionArea>
            </Grid>
        <Grid item md={12}>
        <CardActionArea to="/dashboard/docs/dictionary" component={NavLink} style={{textDecoration: 'none'}}>
                    <Card sx={{p:2, mb:2 }} >
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                            <Box sx={{p:1, px: 9}}>
                                <Abc sx={{ width: 48, height: 48}} />
                            </Box>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography component="div" variant="h6" noWrap sx={{ fontSize: '0.9rem'}}>
                                    Diccionario
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    Sección con las preguntas mas frecuentes que se pueden presentar al utilizar por primera ver al utilizar GuateCompras o nuestra app.
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    <ChromeReaderMode fontSize="small"  />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </CardActionArea>
        </Grid>
        <Grid item md={12}>
            <CardActionArea to="/dashboard/docs/questions" component={NavLink} style={{textDecoration: 'none'}}>
                    <Card sx={{p:2, mb:2 }} >
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                            <Box sx={{p:1, px: 9}}>
                                <QuestionAnswer sx={{ width: 48, height: 48}} />
                            </Box>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography component="div" variant="h6" noWrap sx={{ fontSize: '0.9rem'}}>
                                    Preguntas Frecuentes
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    Sección con las preguntas mas frecuentes que se pueden presentar al utilizar por primera ver al utilizar GuateCompras o nuestra app.
                                </Typography>
                                <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                    <ChromeReaderMode fontSize="small"  />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </CardActionArea>
        </Grid>
        {videos.slice(1).map((data, index)=> (
            <CardActionArea to={`/dashboard/docs/video/${index+1}`} component={NavLink} style={{textDecoration: 'none'}}>
                <Card sx={{ p:2, mb:2 }} >
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <CardMedia
                                component="img"
                                sx={{ width: 120 }}
                                image={data.mini}
                                alt="Muy pronto ..."
                            />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography component="div" variant="h6" noWrap sx={{ fontSize: '0.9rem'}}>
                                {data.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                {data.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: '0.8rem'}}>
                                <ChromeReaderMode fontSize="small"  />
                                <PlayCircleFilledWhite fontSize="small"  />
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </CardActionArea>
        ))}
        </Grid>
    </Container>
    );
};
export default HelpSidebar;
