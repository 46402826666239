import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
// Custom hooks
import useAuth from "../../hooks/useAuth";

import {
    Grid,
    Typography,
    Button,
    Box, 
    Container,
    Card,
} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';



const Wrapper = styled.div`
  ${spacing};
  text-align: center;
  min-height: 100vh;
  min-width: 100%;
  background: url("https://storage.googleapis.com/subtlepatterns-production/designers/subtlepatterns/uploads/shattered.png") repeat;

`;

const VariantContent = styled.div`
  margin-top: 3rem;
  margin-bottom: 4rem;
`;


const PlanAdded = () => { 
    const navigate = useNavigate();
    const { signOut } = useAuth();

    const handleSignOut = async () => {
        navigate("/auth/sign-in");
        await signOut();
      };

    return (
        <CssBaseline>
 <Wrapper pt={16} pb={20} >
                <Container>
                    <Card style={{width: "100%", margin: "5px", }} >
                        <Grid container alignItems="center" justifyContent="center" spacing={4}>
                            <Grid item xs={12} sm={10} md={9} lg={9}>
                                <VariantContent>
                                <Typography variant="h2">
                                    PLAN AGREGADO SATISFACTORIAMENTE
                                </Typography>
                                <Box mb={12} />
                                <Typography variant="h4" color="textSecondary" gutterBottom>
                                    Inicia Sesión para ver los cambios realizados  
                                </Typography>
                                <Box mb={12} />
                                <Button variant="contained" onClick={handleSignOut} endIcon={<LoginIcon />}>
                                    Regresar
                                </Button>
                                </VariantContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </Wrapper>
        </CssBaseline>
           

    )
}


export default PlanAdded