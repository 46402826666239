import axios from "./axios";
import axiosInstance from "./axios.default";

export const create_stripe_subscription = async ( customerId, priceId ) => {
    return await axios.post(`/business/stripe/create/`, { customerId, priceId }) 
    .then((res) => {
        return res.data
    })
    .catch((error) => {
        console.log(error)
        return {"message": "error"}
    });
}

export const stripe_subscription_cancel = async ( subscriptionId ) => {
    return await axios.post(`/business/stripe/cancel/`, { subscriptionId }) 
    .then((res) => {
        return res.data
    })
    .catch((error) => {
        console.log(error)
        return {"message": "error"}
    });
}

export const get_plans = async () => {
    return await axiosInstance.get(`/business/plans/`)
    .then((res) => {
      console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return { message: "error" };
    });
}