import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

// Mui components
import {
    Grid,
    Typography as MuiTypography,
    Container
} from "@mui/material";

// Template components
import VectorMaps from '../../components/VectorMaps/Map'
import TableHome from '../../components/TableHome/TableHome';


// services
import {
    getDeptos,
    getBuyerEntity
} from '../../services/presentation.service'


// Styled components
const Typography = styled(MuiTypography)(spacing);
const Wrapper = styled.div`
  padding-bottom: 1rem;
  position: relative;
  overflow: hidden;
  
`;
const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
  margin: 1.5rem;
`;



const Title = styled(Typography)`
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: 2.5rem;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2.5rem;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 3.9rem;
    text-align: left;
  }
  span {
    color: #000;
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  ${(props) => props.theme.breakpoints.up("xs")} {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: left;
  }
`;




/* 
    This section is the first of the page, 
    it shows the information of the application, 
    as well as a demo of a map with information 
    on contests and a demo table to observe in real 
    time statistics of the system's contests
*/

function Introduction() {

    //This property stores the contests by department, this data is shown on the map
    const [contestsByDepartment, setContestDept] = useState([]);

    //This property saves the contests by entity, this data is shown in the table
    const [contestsByEntity, setContestEntity] = useState([])

    //Get the information to show in the table and on the map
    useEffect(() => {
        const getData = async () => {
            //const data = await getBuyerEntity()
            //console.log(data)
            //const orderData = data.sort(((a, b) => b.quantity - a.quantity))
            //const topData = orderData.slice(0, 6)
            setContestDept(await getDeptos())
            setContestEntity(await getBuyerEntity())
        }
        getData()
    }, [])



    return (
      <Wrapper py={10}>
      <Container maxWidth="xl">
            <Grid container justifyContent="center">
                <Grid item md={6} sx={12} >
                    <Grid height='100%' container alignItems="center" >
                        <Content>
                            <Title variant="h1" gutterBottom>
                                Conoce el nuevo procesador de datos Compras Públicas GT 
                            </Title>
                            <Subtitle color="textSecondary">
                                Aumenta las adjudicaciones a tu empresa analizando eficientemente el historial de compras públicas y suscribiéndose a palabras clave para ser notificado cuando nuevos eventos sean publicados.                                      
                            </Subtitle>
                        </Content>
                    </Grid>
                </Grid>
                <Grid item md={6} sx={12} >
                    <Grid p={10}>
                        <VectorMaps data={contestsByDepartment} disclaimer="vigentes" />
                    </Grid>
                </Grid>
            </Grid>
                <Container maxWidth="md">
                <TableHome row={contestsByEntity}/>   
                </Container>
            </Container>
        </Wrapper>
    );
}

export default Introduction;