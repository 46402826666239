import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Grid, Typography, Button as MuiButton, CardMedia, Container } from "@mui/material";

import { spacing } from "@mui/system";

const Wrapper = styled.div`
    ${spacing};
    position: relative;
    background: #5271ff;
    color: ${(props) => props.theme.palette.common.black};
    &:hover {
        color: ${(props) => props.theme.sidebar.color};
    }
    padding: 80px 0;
`;

const Text = styled(Typography)`
    color: #fff;
    ${(props) => props.theme.breakpoints.up("xs")} {
        text-align: center;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
        text-align: left;
    }
`;

const Title = styled(Typography)`
    width: 100%;
    line-height: 1.4;
    font-size: 1.75rem;
    color: #fff;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    ${(props) => props.theme.breakpoints.up("xs")} {
        font-size: 1.7rem;
        text-align: center;
    }
    ${(props) => props.theme.breakpoints.up("sm")} {
        font-size: 1.7rem;
        text-align: center;
        
    }
    ${(props) => props.theme.breakpoints.up("md")} {
        font-size: 2.2rem;
        text-align: left;
    }
    span {
        color: #fff;
    }
`;

const ImgContainer = styled.img`
    ${(props) => props.theme.breakpoints.up("xs")} {
        width: 100%;
    }
    ${(props) => props.theme.breakpoints.up("sm")} {  
        width: 100%;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
        width: 570px;
    }
`;



const Cont = styled(Container)`
    ${(props) => props.theme.breakpoints.up("xs")} {
        text-align: center;
    }
    ${(props) => props.theme.breakpoints.up("sm")} {  
        text-align: center;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
        text-align: left;
    }
`;

const Button = styled(MuiButton)`
  width: 330px;
  font-size: 1.2rem;
  font-weight: 4rem;
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
`;

const News = () => {
    const ButtonLink = () => {
        window.open('https://cursos.guatedatasuite.com/prensa', "_blank")
        return null;
    }

    return (
       <Wrapper>
            <Container>
                <Grid container>
                    <Grid item md={12} sx={12} >
                        <Grid container spacing={{ xs: 12, md: 2 }} >
                            <Grid item  md={6} sm={12} xs={12} >
                                <Container align="center" >
                                    <ImgContainer style={{borderRadius: 40}} width={100} src="https://res.cloudinary.com/dos6zlnm9/image/upload/v1705600143/front%20doc/j0wicvm4tm0plf2b4rhs.jpg" alt="PNI" />
                                </Container>
                            </Grid>
                            <Grid item md={6} sx={12}>
                                <Grid container  alignItems="center" >
                                    <Grid item  md={12} sx={12} >
                                        <Grid container>
                                            <Title style={{ padding: '0 0 30px 0'}} >
                                                Ganadores del Premio Nacional de Innovación 2023
                                            </Title>
                                            <Text variant="h3" component="div" >
                                                Nuestro software para proveedores del Estado recibió el Premio Nacional de Innovación 2023 
                                                por parte de la Secretaria Nacional de Ciencia y Tecnología (SENACYT).
                                            </Text>
                                            <br />
                                            <Cont >
                                                <Button color="primary" variant="contained" onClick={() => ButtonLink()}>
                                                    Conoce más
                                                </Button>
                                            </Cont>
                                           
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>      
       </Wrapper>
    )
}


export default News;