import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import { Container, Grid, Typography, Button as MuiButton, } from "@mui/material";
import { spacing } from "@mui/system";

const Title = styled(Typography)`
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.7rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.2rem;
  }
  span {
    color: #000;
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
  margin-top: 5rem;
`;

const ImageWrapper = styled.div`
  border-radius: 10px;
  &:before {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
    bottom: 0;
    left: 0;
    position: fixed;
    content: " ";
    z-index: 1;
    display: block;
    width: 100%;
    height: 75px;
    pointer-events: none;
  }
`;

const Button = styled(MuiButton)`
  width: 330px;
  font-size: 1.2rem;
  font-weight: 4rem;
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
`;

const toMatch = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i
];

const Variant = ({ title, description, image }) => {
  return (
      <Grid item xs={12} sm={12} md={12} lg={12} py={10} >
        <Grid container spacing={4} alignItems="center">
          <Grid item  md={12} sx={12}>
            <Title >
              {title}
            </Title>
            <Subtitle >
              {description}
            </Subtitle>
          </Grid>
          <Grid item md={12} sx={12}>
          <ImageWrapper>
            <video style={{maxWidth: '100%', height: 'auto', display: 'center', boxShadow: '0 6px 18px 0 rgba(18, 38, 63, 0.075)', borderRadius: '10px'}} autoplay="autoplay" loop="loop" muted="muted" src={image}></video>
           </ImageWrapper>
          </Grid>
        </Grid>
      </Grid>
  );
};

function Demos() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const detectMob = () => {
      setMobile(toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      }))
    }
      detectMob()
  }, [])

//
  return (
    <Wrapper pb={20} id="demos">
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Variant image={"https://fotos-perfil-procesadorguatec.s3.us-east-2.amazonaws.com/docs/bus-hist-gif-gds.mp4"} title="Analiza un historial de más de 1,000,000 de eventos" description="Encuentra las palabras clave que se adapten a tu catálogo de productos. Analiza precios, marcas y demás variables."  />
          <Variant image={"https://fotos-perfil-procesadorguatec.s3.us-east-2.amazonaws.com/docs/portafolio.mp4 "} title="Crea portafolios de palabras clave" description="Visualiza el número de eventos de tu interés que serán publicados en los próximos meses y optimiza tus palabras clave para maximizar ese número. " />
          <Variant image={"https://fotos-perfil-procesadorguatec.s3.us-east-2.amazonaws.com/docs/notificaciones.mp4"} title="Recibe notificaciones" description="Se el primero en enterarte cuando un evento de tu interés sea publicado. Prepara tus ofertas con tiempo y aumenta las adjudicaciones a tu empresa." />
          <Button
                ml={2}
                color="primary"
                variant="contained"
                href="/auth/sign-up"
                target="_blank"
              >
                Crea una cuenta gratis
              </Button>
              
              
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Demos;
