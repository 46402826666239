import React from "react";
import styled from "styled-components/macro";
// Mui components
import { 
  Container, 
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
// Template components
import {
  Grid,
} from "@mui/material";

import logo from '../../vendor/favicon.svg'

import CardMedia from '@mui/material/CardMedia';

const Typography = styled(MuiTypography)(spacing);

const Logo = styled(CardMedia)`
${(props) => props.theme.breakpoints.up("xs")} {
  width: 300px;
}
${(props) => props.theme.breakpoints.up("sm")} {
  width: 350px;
}
${(props) => props.theme.breakpoints.up("md")} {
  width: 450px;
}
`;
const Text = styled(Typography)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: left;
  }
`;

const Title = styled(Typography)`
  width: 100%;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: 1.7rem;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.7rem;
    text-align: center;
    
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.2rem;
    text-align: left;
  }
  span {
    color: #000;
  }
`;

const Wrapper = styled.div`
  padding: 5rem 1rem 5rem 1rem;
  position: relative;
  overflow: hidden;
  background: #F7F9FC;
`;



/*
  This function is used to display different section titles
  Props
  titleVariant: text variant, can be
                - h1
                - h2
                - h3
                - etc
                
  title: section title 
  children: section text
*/




/**
 * 
 * This section will be used to display company information
 */


const AboutUs = () => {
  return (
    <Wrapper >
      <Container  maxWidth="xl">
        <Grid container >
          <Grid item md={12} sx={12} >
          <Grid container spacing={{ xs: 12, md: 2 }} >
            <Grid item  md={6} sm={12} xs={12} >
                <Container align="center" >
                  <Logo
                    component="img"
                    image={logo}
                    alt="logo"
                  />
                </Container>
            </Grid>
            <Grid item md={6} sx={12}>
            <Grid container  alignItems="center" >
                        <Grid item  md={12} sx={12} >
                            <Grid container>
                              <Title style={{ padding: '0 0 30px 0'}} >
                                Sobre Nosotros
                              </Title>
                              <Text variant="body2" component="div" >
                              En Guate Data Suite creemos que una decisión informada tiene mayores probabilidades de alcanzar el resultado deseado. Nuestro trabajo es procesar y estructurar información de fuentes públicas para que empresas, organizaciones y personas individuales puedan tomar decisiones inteligentes de forma rápida y eficiente.
                                </Text>
                                <br/> 
                              <Title style={{ padding: '30px 0 30px 0'}}>
                                Compras Públicas GT
                              </Title>
                              <Text variant="body2" component="div" >
                                Con nuestro nuevo producto Compras Públicas GT comprenderás a profundidad cómo funciona el mercado de ventas a entidades públicas en Guatemala y podrás encontrar oportunidades únicas para tu negocio, sin importar si tienes años de experiencia en el mercado o apenas estás iniciando.
                              </Text>
                              <Text variant="body2" component="div" >
                                Los datos son obtenidos legalmente de fuentes públicas a través de un proceso respaldado por la Constitución de la República de Guatemala, Ley de Acceso a la Información Pública y Ley de Contrataciones del Estado.              
                              </Text>
                              <br/> 
                            </Grid>
                        </Grid>
                     </Grid>
              
            </Grid>
          </Grid>
            
          </Grid>
        </Grid>
      </Container>
       
        
      
    </Wrapper>

    
  );
}

export default AboutUs;