import {
  Briefcase,
  Calendar,
  CheckSquare,
  Search,
  PieChart,
  Users,
  Home,
  BarChart2,
  HelpCircle,
  Zap,
  Info,
  Grid,
  AlertOctagon
} from "react-feather";

const section1 = [
  {
    href: "/dashboard/home",
    icon: Home,
    title: "Página Principal",
  },
];

const section2 = [
  {
    href: "/dashboard/current-search",
    icon: Search,
    title: "Eventos Vigentes",
  },
  {
    href: "/dashboard/price-search",
    icon: Zap,
    title: "Precios Recientes",
  }, 
];


const section3 = [
  {
    href: "/dashboard/followers",
    icon: Users,
    title: "Seguidores",
  },
  {
    href: "/dashboard/subscriptions",
    icon: CheckSquare,
    title: "Suscripciones",
  },
  {
    href: "/dashboard/calendar",
    icon: Calendar,
    title: "Calendario",
  },
];

const section4 = [
  {
    href: "/dashboard/current-search",
    icon: Search,
    title: "Eventos Vigentes",
  },
];

const section5 = [
  {
    href: "/dashboard/current-search",
    icon: Search,
    title: "Eventos Vigentes",
  },
];

export const navItemsfree = [
  {
    title: "Home",
    pages: section1,
  },
  {
    title: "Búsqueda de eventos",
    pages: section4,
  },
  {
    title: "Control de notificaciones",
    pages: section3,
  },
];

export const navItemsPS = [
  {
    title: "Home",
    pages: section1,
  },
  {
    title: "Búsqueda de eventos",
    pages: section5,
  },
  {
    title: "Control de notificaciones",
    pages: section3,
  },
];

export const navItems = [
  {
    title: "Home",
    pages: section1,
  },
  {
    title: "Búsqueda de eventos",
    pages: section2,
  },
  {
    title: "Control de notificaciones",
    pages: section3,
  },
  /**
  {
    title: "Mejora de ventas",
    pages: section4,
  },
   */

];

const footerPages = [
  {
    href: "/dashboard/help",
    icon: HelpCircle,
    title: "Ayuda",
  },
  {
    href: "/support",
    icon: AlertOctagon,
    title: "Reportar Error",
    target: '_blank',
  },
];

export const footerItems = [
  {
    title: "Documentación",
    pages: footerPages,
  },
];

const adminPages = [
  {
    href: "/administration/control-panel",
    icon: BarChart2,
    title: "Panel de control",
  },

  {
    href: "/dashboard/historical-search",
    icon: PieChart,
    title: "Eventos Históricos",
  },
  {
    href: "/dashboard/portfolios",
    icon: Briefcase,
    title: "Portafolios",
  },
  {
    href: "/dashboard/blog/create",
    icon: Grid,
    title: "Crear Post",
  },
  {
    href: "/dashboard/blog",
    icon: Info,
    title: "Blog",
  },
  /**
   {
    href: "/administration/control-users",
    icon: Users,
    title: "Usuarios",
  },
   */
];

export const adminItems = [
  {
    title: "Administración",
    pages: adminPages,
  },
];
