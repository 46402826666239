import React, { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Field } from "formik";

import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";
import useNotify from "../../hooks/useNotify";


import { FormikForm, CustomInputComponent, PasswordInputComponent } from "../../components/Form/Formik/FormikForm"

//Mui components
import {
    Grid,
    Paper,
    Typography,
} from "@mui/material";



import Link from '@mui/material/Link';

import { NavLink, useNavigate } from "react-router-dom";
import logo from '../../vendor/favicon.svg'

// validation schemas
import { emailSchema } from "../../utils/validationSchemas";

// Styled components
const Wrapper = styled(Paper)`
padding: ${(props) => props.theme.spacing(6)};
`;

const Brand = styled(Paper)`
  color: #5271ff;
  background-color: rgba(255, 255, 255, 0);
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  height: 120px;
  width: 370px;
  &:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  text-decoration: none;
    
`;

const BrandIcon = styled.div`
  margin-right: ${(props) => props.theme.spacing(6)};
  margin-top: -2px;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
`;


/**
 * Default values ​​of the form, these values ​​are not valid and are not sent until the required fields are filled.
 */
const initialValues = {
    email: "",
    password: "",
    submit: false,
}

/*
    Object to validate the entry of the elements, 
    that are required, that meet requirements such as size, the passwords must match, etc.
*/
const validationSchema = Yup.object().shape({
    email: emailSchema,
    password: Yup.string().max(255).required("La contraseña es requerida"),
})

/**
 * This view is used to create an account in the application
 * 
 */

const VALID_STATUS = 200;

const SignInPage = () => {

    /*
        use the signIn function of the custom hook, this function receives the parameters to log into the account
    */
   
    const { notifyShow } = useNotify();
    const { signIn } = useAuth();
    const navigate = useNavigate();



    /*
    That function sends the data to the api to start the session 
    */
    const signInFunction = async (values) => {
        /*
        Once the fields are filled, it sends the information to the signIn function, 
        which in turn sends it to the api to validate if the user was created and access the application.
        */
        const response = await signIn(values.email, values.password);
        if (response?.status !== VALID_STATUS) notifyShow("correo o contraseña inválidos.", "error", "filled", ["button", "center"])

        if (response?.status === VALID_STATUS) {
            const { user } = response.data
            notifyShow("¡Bienvenido "+user.first_name+" " +user.last_name +"!", "success", "filled", ["button", "center"])
            navigate("/dashboard/home");
        }
    }

    return (
        <Fragment>
            <Helmet title="Sign In" />
            <Brand component={NavLink} to="/" className="fontFamily"  >
                <Grid container alignItems="center" spacing={12}>
                    <Grid item md={2} xs={2} sm={2}>
                        <BrandIcon style={{backgroundImage: `url(${logo})` }}></BrandIcon>
                    </Grid>
                    <Grid item md >
                     GUATE DATA SUITE
                    </Grid>
                </Grid>
            </Brand>
            <Wrapper>
            
                
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                    ¡Hola de nuevo!
                </Typography>
                <Typography component="h2" variant="body1" align="center">
                    Inicia sesión en tu cuenta para continuar
                </Typography>
                <br />
                { /* 
                Initialize the form with the initial values ​​and the validation scheme
                */
                }
                <FormikForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    functionToExecute={signInFunction}
                    buttonProps={{ fullWidth: true }}
                    titleButton="Iniciar sesión"
                >
                    <Grid container >

                        <Field type="email" name="email" label="Correo electrónico" as={CustomInputComponent} />
                        <Field  name="password" label="Contraseña" as={PasswordInputComponent} />

                    </Grid>
                    <br />

                </FormikForm>
                <Link key="reset" sx={{color: '#5271ff'}} href="/auth/reset-password"  >Olvidé mi contraseña</Link>
                <br />
                <Link key="register" sx={{color: '#5271ff'}} href="/auth/sign-up" >¿Todavía no tienes una cuenta? <Link style={{ fontWeight: 'bold', color: '#5271ff'}}> Regístrate aquí </Link> </Link>
                <br />
                <Link key="support" sx={{color: '#5271ff'}} href="/support" target="_blank">¿Encontraste problemas técnicos?<Link style={{ fontWeight: 'bold', color: '#5271ff'}}> Escríbenos y te ayudaremos lo más pronto posible.</Link> </Link>
            </Wrapper>
        </Fragment>
    );
}

export default SignInPage
