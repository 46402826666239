import React, { useState } from "react";
import { anual_codes, montly_codes } from "../../utils/plans.codes";
// import the navbar

import {
  Container,
  Grid,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  DialogTitle,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// Styled components

const SubscriptionsViews = ({ open, setOpen, setPlan, basicPlan }) => {
  const [mood, setMood] = useState("annual");
  const [backgound, setBackgound] = useState("#fec601");

  const handleMood = (e, newMood) => {
    if (newMood !== null) {
      setMood(newMood);
      setBackgound(newMood === "annual" ? "#fec601" : "#5271ff");
    }
  };

  const createData = (name,  simple, standard, advanced) => {
    return { name, simple, standard, advanced };
  };

  const selectPlan = (plan_update) => {
    localStorage.setItem("plan", plan_update);
    setPlan(plan_update);
    setOpen(false);
  };

  const getButtons = (type) => {
    var color = "primary";
    var plans = anual_codes;
    if (type === "monthly") {
      color = "info";
      plans = montly_codes;
    }

    return plans.map((plan) => (
      <>
        {(basicPlan ? true : plan !== "BAA" && plan !== "BA") && (
          <TableCell key={plan + color} align="center">
            <Button
              key={plan}
              fullWidth
              variant="contained"
              color={color}
              onClick={() => selectPlan(plan)}
            >
              Elegir plan
            </Button>
          </TableCell>
        )}
      </>
    ));
  };

  const rows = {
    annual: [
      createData('Precio', "Q4800/año", "Q15000/año", "Q30000/año"),
      createData('Número de suscripciones para recibir notificaciones', "Ilimitado", "Ilimitado", "Ilimitado"),
      createData('Suscripción y rastreo de palabras clave', "10 palabras", "10 palabras", "50 palabras"),
      createData('Usuarios para recibir notificaciones', "3 usuarios", "3 usuarios", "10 usuarios"),
      createData('Búsqueda de precios y marcas', "", "Ilimitado", "Ilimitado"),
      createData('Notificaciones de eventos en tiempo real', "Ilimitado", "Ilimitado", "Ilimitado"),
      createData('Soporte técnico', "Ilimitado", "Ilimitado", "Ilimitado"),
],
    monthly: [
      createData('Precio', "Q550/mes", "Q1400/mes", "Q3200/mes"),
      createData('Número de suscripciones para recibir notificaciones', "Ilimitado", "Ilimitado", "Ilimitado"),
      createData('Suscripción y rastreo de palabras clave', "10 palabras", "10 palabras", "50 palabras"),
      createData('Usuarios para recibir notificaciones', "3 usuarios", "3 usuarios", "10 usuarios"),
      createData('Búsqueda de precios y marcas', "", "Ilimitado", "Ilimitado"),
      createData('Notificaciones de eventos en tiempo real', "Ilimitado", "Ilimitado", "Ilimitado"),
      createData('Soporte técnico', "Ilimitado", "Ilimitado", "Ilimitado"),
],
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth={true}
    >
      <Grid container>
        <Grid item md={1}>
          <DialogTitle>Planes</DialogTitle>
        </Grid>
        <Grid item xs>
          <DialogActions>
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" align={"center"}>
                  <ToggleButtonGroup
                    value={mood}
                    exclusive
                    onChange={handleMood}
                    color={mood === "annual" ? "primary" : "info"}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="annual" aria-label="left aligned">
                      Anual
                    </ToggleButton>
                    <ToggleButton value="monthly" aria-label="centered">
                      Mensual
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Typography>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
        <Grid item md={1}>
          <DialogActions>
            <Typography variant="body2" align={"right"}>
              <Tooltip title="cerrar">
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </DialogActions>
        </Grid>
      </Grid>

      <DialogContent>
        <Container>
          <Grid container justifyContent="center">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: backgound }}>
                  <TableRow>
                    <TableCell
                      sx={
                        mood === "annual"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                    >
                      Elije el plan mas acoplado a tus necesidades
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={
                        mood === "annual"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                    >
                      Proveedor Estándar
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={
                        mood === "annual"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                    >
                      Proveedor Avanzado
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={
                        mood === "annual"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                    >
                      Proveedor Experto
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows[mood].map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.simple}</TableCell>
                      <TableCell align="center">{row.standard}</TableCell>
                      <TableCell align="center">{row.advanced}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    {getButtons(mood, basicPlan)}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionsViews;
