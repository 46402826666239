import { verify, sign } from "jsonwebtoken";

const setSession = (refreshToken, accessToken, user) => {
  if (accessToken) {
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.clear();
  }
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
}

export { verify, sign, setSession, setUser };
