import axios from "axios";
import { URL } from "../constants";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import useRefreshToken from "../hooks/useRefreshToken";

const axiosInstance = axios.create({
  baseURL: URL,
  headers: { "content-type": "application/json" },
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          const newAccessToken = localStorage.getItem("accessToken")
          config.headers["Authorization"] = `JWT ${newAccessToken}`;
        }
        return config;
      }, (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          const newAccessToken = await refresh();
          if (!newAccessToken) {
            navigate("/auth/sign-in");
          } else {
            localStorage.setItem("accessToken", newAccessToken);
            prevRequest.headers["Authorization"] = `JWT ${newAccessToken}`;
            return axiosInstance(prevRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [navigate, refresh]);

  return children;
};

export default axiosInstance;
export { AxiosInterceptor };
