
import * as Yup from "yup";

export const emailSchema = Yup.string()
    .email("Ingresar un correo electrónico válido")
    .max(255)
    .required("El correo electrónico es requerido")


export const passwordSchema = Yup.string()
    .required('Ingresa tu contraseña')
    .matches(
        /* eslint-disable no-useless-escape */
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Debe contener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial") 
    .max(255)

export const passwordAndConfirmSchema = {
    password: passwordSchema,
    confirm_password: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Las contraseñas deben ser iguales"
        ),
    }).required('Confirmar la contraseña')
}

export const passwordAndConfirmSchemaNew = {
    password: passwordSchema,
    confirm_password_new: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Las contraseñas deben ser iguales"
        ),
    }).required('Confirmar la contraseña')
}

export const phoneNumberSchema = Yup.string()
    .required("El número es requerido")
    .min(8, "El número debe tener 8 dígitos")
    .max(8, "El número debe tener 8 dígitos")

