import { useContext } from "react";

import { NotifyContext } from "../contexts/NotifyContext";

const useNotify = () => {
  const context = useContext(NotifyContext);

  if (!context)
    throw new Error("NotifyContext must be placed within NotifyProvider");

  return context;
};

export default useNotify;