import react, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
    Button,
    TextField,
    Alert,
    Box, 
    CircularProgress,
    Container
} from "@mui/material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AppBar from '../presentation/AppBar';
import Footer from '../presentation/Footer'

import useNotify from "../../hooks/useNotify";
import useAuth from "../../hooks/useAuth";
import { support_help, cloudinary_upload } from '../../services/support.service';
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../../utils/GlobalStyle";




const DropContainer = styled.label`
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    margin: 10px 0;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    &:hover { 
        background: #eee;
        border-color: #111;
    }
`

const DropTitle = styled.label`
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
`

const DropImage = styled.input.attrs({type:'file'})`
    display: none;
`

const CLOUDINARY_CLOUD_NAME = "guate-data-suite";
const CLOUDINARY_UPLOAD_PRESET = "zw5v2thh";
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`;

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const TextFieldCompenent = ({name, label, multiline, rows, value, onBlur, onChange, error, helperText}) => {
    return (
        <TextField 
            name={name}
            label={label}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            type="text"
            variant="outlined"
            sx={{my:2}}
            autoComplete="off"
            error={error}
            helperText={helperText}
            multiline={multiline}
            rows={rows}
        />
    )
}

const HelpAuth = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { notifyShow } = useNotify();
    const [ imageData, setImageData ] = useState();

    const initialValues = {
        name: user? user.first_name + " " + user.last_name : '',
        phone: user?.phone_number_sms?? '',
        email: user?.email?? '',
        message: '',
        image: '',
        userAgent: '', 
    }
    

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("El nombre es requerido"),
        email: Yup.string()
            .email("Debe ingresar un correo válido")
            .max(255)
            .required("El correo es requerido"),
        phone: Yup.string()
            .matches(/^\+502\d{8}$|^\d{8}$/, 'Número de teléfono inválido')
            .required('El número de teléfono es requerido'),
    });

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            let image_url = "";
            if (imageData) { 
                const formData = new FormData();
                formData.append("file", imageData[0]);
                formData.append("upload_preset",CLOUDINARY_UPLOAD_PRESET || "");
    
                image_url = await cloudinary_upload(CLOUDINARY_URL, formData)
            }

            values.image = image_url;
            values.userAgent = navigator.userAgent;
            const message_slack = await support_help(values)
            notifyShow(message_slack?.message, "success")
        
            await timeOut(1000);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
        } catch (error) {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
        }
    };


    return (
        <>
            <CssBaseline />
            <AppBar />
            <Container maxWidth="md" sx={{my:20}}>
                <Formik
                    initialValues={
                    initialValues
                    } /*the initial values ​​of the form will be those that come in the follower object */
                    validationSchema={validationSchema} /*form field validations*/
                    onSubmit={handleSubmit}
                >
                    {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                    }) => (
                    <>
                        {status && status.sent && (
                            <Alert severity="success">Nos pondremos en contacto lo antes posible</Alert>
                        )}

                        {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={6}>
                            <CircularProgress />
                        </Box>
                        ) : ( 
                            <form onSubmit={handleSubmit}>
                                <TextFieldCompenent 
                                    name="name"
                                    label="Nombre"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                <TextFieldCompenent 
                                    name="phone"
                                    label="Whatsapp"
                                    value={values.phone}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                                <TextFieldCompenent 
                                    name="email"
                                    label="Correo Electronico"
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                                <TextFieldCompenent 
                                    name="message"
                                    label="Mensaje"
                                    value={values.message}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    multiline={true}
                                    rows={3}
                                />
                                {imageData && (
                                    <Alert severity="success">
                                        Imagen subida con exito
                                    </Alert>
                                )}
                                <DropContainer>
                                    <DropTitle>Subir imagen (opcional)</DropTitle>
                                    <CloudUploadIcon/> 
                                    <DropImage
                                        type="file"
                                        name="file"
                                        onChange={e => setImageData(e.target.files)}
                                        accept="image/jpeg"
                                    />
                                </DropContainer>
                            

                                <Button
                                    fullWidth
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Reportar
                            </Button>
                            </form>
                        )}
                        </>
                    )}
                        </Formik>

            </Container>
            <Footer/>
        </>
      
       
    )
}

export default HelpAuth;
