import React, { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";

import DialogPlans from "../../pages/plans/DialogPlans";
import Payment from "../../pages/payments/Payment";
import { plans_all } from "../../utils/plans.codes";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Button,
} from "@mui/material";

import {
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";

import useAuth from "../../hooks/useAuth";

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [openPlansView, setOpenPlansView] = useState(false);
  const [openPaymentView, setPaymentPlansView] = useState(false);

  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleProfile = async () => {
    await closeMenu();
    navigate("/dashboard/profile");
  };
  const handleSignOut = async () => {
    navigate("/auth/sign-in");
    await signOut();
  };

  const [plan_act, setPlanAct] = useState();

  const changePlan = (plan_update) => {
    const plan_actFind = plans_all.filter((item) => item.plan === plan_update);
    setPlanAct(plan_actFind);
    setPaymentPlansView(true);
  };

  return (
    <Fragment>
      {user?.plan?.code === "BA" && (
        <Button
          variant="contained"
          sx={{ mx: 6 }}
          onClick={() => setOpenPlansView(true)}
        >
          Mejora tu plan
        </Button>
      )}

      <Tooltip title="Configurar cuenta">
        <IconButton
          onClick={handleProfile}
          color="inherit"
          size="large"
        >
          <SettingsIcon/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Cerrar sesión">
        <IconButton
          onClick={handleSignOut}
          color="inherit"
          size="large"
        >
          <ExitToAppIcon/>
        </IconButton>
      </Tooltip>

      {openPlansView && (
        <DialogPlans
          open={openPlansView}
          setOpen={setOpenPlansView}
          setPlan={changePlan}
          basicPlan={false}
        />
      )}
      {openPaymentView && (
        <Payment
          open={openPaymentView}
          setOpen={setPaymentPlansView}
          plan={plan_act[0]}
        />
      )}
    </Fragment>
  );
}

export default NavbarUserDropdown;
