import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import "../../vendor/font/styles.css";
// Mui components
import {
  Button as MuiButton,
  Container,
  Grid,
  Hidden,
  ListItemButton,
} from "@mui/material";

import { NavLink } from "react-router-dom";
import logo from "../../vendor/favicon.svg";

// Styled components
const Button = styled(MuiButton)`
  padding: 10px;
  border-radius: 10px;

  ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: 0.9rem;
    margin: 0 0 0 8px;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 0.9rem;
    margin: 0 0x 0 10px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1rem;
    margin: 0 0 0 20px;
  }
`;

const Brand = styled(ListItemButton)`
  color: #5271ff;
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  ${(props) => props.theme.breakpoints.up("xs")} {
    width: 300px;
    font-size: ${(props) => props.theme.typography.h6.fontSize};
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 320px;
    font-size: ${(props) => props.theme.typography.h4.fontSize};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 450px;
    font-size: ${(props) => props.theme.typography.h3.fontSize};
  }
`;

const BrandIcon = styled.div`
  margin-right: ${(props) => props.theme.spacing(6)};
  margin-top: -2px;
  background-repeat: no-repeat;
  ${(props) => props.theme.breakpoints.up("xs")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 60px;
    height: 60px;
  }
`;

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
  margin-top: 3rem;
`;

const GridPare = styled(Grid)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    text-align: center;
    display: "inline";
    justify-content: center;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    text-align: center;
    display: "inline";
    justify-content: center;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: right;
    display: "inline";
    justify-content: left;
  }
`;

//fill: ${(props) => props.theme.sidebar.header.brand.color};
//color: ${(props) => props.theme.sidebar.header.brand.color};

/*
    This section represents the top navbar in the initial view, 
    it contains the buttons to login or create an account.
*/
const AppBarComponent = ({ timeOut }) => {
  const contact = () => {

    setTimeout(()=>{
      scroller.scrollTo("contact", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
  }, timeOut);
    
  };

  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Grid container alignItems="center">
          <GridPare item md={4} xs={12}>
            <Container align="center">
              <Brand component={NavLink} to="/" className="fontFamily">
                <BrandIcon
                  style={{ backgroundImage: `url(${logo})` }}
                ></BrandIcon>
                GUATE DATA SUITE
              </Brand>
            </Container>
          </GridPare>
          <Grid item xs />
          <GridPare item md={6} xs={12}>
            <Hidden>
                <Button ml={2} color="inherit" onClick={contact} component={NavLink} to="/">
                  Comunicate con nosotros
                </Button>

              <Button
                ml={2}
                color="inherit"
                component={Link}
                to="/auth/sign-in"
                target="_blank"
              >
                Inicia sesión
              </Button>
            </Hidden>
            <Button
              ml={2}
              color="primary"
              variant="contained"
              href="/auth/sign-up"
              target="_blank"
            >
              Regístrate gratis
            </Button>
          </GridPare>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default AppBarComponent;
