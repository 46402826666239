import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import {
  Container,
  Grid,
  Typography ,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// Styled componets


const Wrapper = styled.div`
  ${spacing};
  position: relative;
  background: #5271ff;
  color: ${(props) => props.theme.palette.common.black};
`;

const GridPer = styled(Grid)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: left;
  }
`;

const GridPare = styled(Grid)`
${(props) => props.theme.breakpoints.up("xs")} {
  text-align: center;
  display: 'inline';
  justify-content: center;
}
${(props) => props.theme.breakpoints.up("sm")} {
  text-align: center;
  display: 'inline';
  justify-content: center;
}
${(props) => props.theme.breakpoints.up("md")} {
  text-align: right;
  display: 'inline';
  justify-content: left;
}
`;

//This is the footer of the initial page
function Footer() {
  return (
    <Wrapper pt={16} pb={16}>
      <Container>
      <Grid container  style={{ color: '#fff',}}> 
          <GridPer item xs={12} md={6} >
            <Typography>Suscríbete a nuestras noticias</Typography>
            <GridPare container xs={{ alignItems: 'center'}}>
               
              <div>
              <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350}} >
              <InputBase
                sx={{ ml: 1, flex: 1, color: '#000' }}
                placeholder="Correo electrónico"
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <AttachEmailIcon sx={{ color: '#5271ff'}}/>
              </IconButton>
            </Paper>
              </div>
            </GridPare>
            
            
            <Grid container> 
              <Grid item xs={12}>
                <IconButton  sx={{ p: '0 10px 0 0' }} href="https://www.facebook.com/Guate-Data-Suite-101206819346779">
                  <FacebookIcon sx={{ color: '#fff'}}  />
                </IconButton>
                <IconButton  sx={{ p: '10px' }} href="https://instagram.com/guatedatasuite?igshid=YmMyMTA2M2Y=" >
                  <InstagramIcon sx={{ color: '#fff'}}  />
                </IconButton>
                <IconButton  sx={{ p: '10px' }} href="https://www.youtube.com/channel/UCIHYl9oMyIIMmpEgjEj7TAw/featured">
                  <YouTubeIcon sx={{ color: '#fff'}}  />
                </IconButton>
                <IconButton sx={{ p: '10px' }} href="https://www.linkedin.com/company/guate-data-suite/" >
                  <LinkedInIcon sx={{ color: '#fff'}}  />
                </IconButton>
              </Grid>
            </Grid>
          </GridPer>
          <GridPare item xs={12} md={6} align='right'>
            <Typography>Acerca de</Typography>
            <Link key="home" component={NavLink} to="/terms">Términos y Condiciones</Link>
          </GridPare>
          
        </Grid>
      </Container>
        
    </Wrapper>
  );
}

export default Footer;
