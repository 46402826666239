export const anual_codes = ["PSA", "STDA", "PAA"];
export const montly_codes = ["PS", "STD", "PA"];

export const amount_plans = [
  { amount: 0, code: "BAA" },
  { amount: 620, code: "PSA" },
  { amount: 1860, code: "STDA" },
  { amount: 3780, code: "PAA" },
  { amount: 0, code: "BA" },
  { amount: 70, code: "PS" },
  { amount: 175, code: "STD" },
  { amount: 400, code: "PA" },
];

export const plans_all = [
  { plan: "BA",   name: "Básico",             month: "mes", price: "gratis", amount: 0,    background: "#cccccc", color: "#000000", variant: 'default' },
  { plan: "PS",   name: "Proveedor Estándar", month: "mes", price: "Q550",   amount: 70,   background: "#5271ff", color: "#ffffff", variant: 'info' },
  { plan: "STD",  name: "Proveedor Avanzado", month: "mes", price: "Q1,400", amount: 175,  background: "#5271ff", color: "#ffffff", variant: 'info' },
  { plan: "PA",   name: "Proveedor Experto",  month: "mes", price: "Q3,200", amount: 400,  background: "#5271ff", color: "#ffffff", variant: 'primary' },
  { plan: "BAA",  name: "Básico",             month: "año", price: "gratis", amount: 0,    background: "#cccccc", color: "#000000", variant: 'default' },
  { plan: "PSA",  name: "Proveedor Estándar", month: "año", price: "Q4,800", amount: 620,  background: "#fec601", color: "#000000", variant: 'info' },
  { plan: "STDA", name: "Proveedor Avanzado", month: "año", price: "Q15,000",amount: 1860, background: "#fec601", color: "#000000", variant: 'info' },
  { plan: "PAA",  name: "Proveedor Experto",  month: "año", price: "Q30,000",amount: 3780, background: "#fec601", color: "#000000", variant: 'primary' }, 
  { plan: "PAAPBR", name: "Prueba",           month: "año", price: "$3,780", amount: 3780, background: "#fec601", color: "#000000", variant: 'primary' }, 
];

export const PLANS = {
  BASIC: 'BA',
  STANDARD: 'STD',
  ADVANCED: 'PA',
  BASIC_ANUAL: 'BAA',
  STANDARD_ANUAL: 'STDA',
  ADVANCED_ANUAL: 'PAA',
  SIMPLE: 'PS',
  SIMPLE_ANUAL: 'PSA'
}